import { Component, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { LangService, ApiService } from './services';
declare var jQuery: any;
// import { data } from './blog/data/blog-data';
import { Contact } from '../app/model/contact.model';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { GeolocationService } from './geolocation.service';
import { environment } from '../environments/environment';
import { RecaptchaService } from './services/app-recaptcha.service';
import { BlogService } from './blog/service/blog-service';
import { LanguageService } from './services/language.service';
import { LinkService } from './services/link.service';
import { MetaDataService } from './services';
import { PreviousUrlService } from './services/previous-url.service';
declare var grecaptcha: any;
declare global {
  interface Window {
    grecaptcha: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public current_lang: any = {};
  public copyrightYear = new Date().getFullYear();
  public userLanguage: string;
  public userCountry: string;

  @ViewChild('userName') userName: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  public um: any = {};

  subscribeFrom = this.formBuilder.group({
    email: ['', Validators.required],
  });

  submitted = false;
  subscribe: any = '';
  blogs: any = [];
  public contact: Contact = new Contact();
  countryCode: string = '+61';
  countryName: string = 'aus';
  recaptchaResponse: string | null = null;
  title = "dicom-meoto-angualar9";
  widgetId: string = ''

  onCountryChange(event: any) {
    this.contactForm.get('countryCode')!.setValue(`+${event.dialCode}`);
    this.contactForm.get('countryName')!.setValue(event.iso2);
  }

  contactForm = this.formBuilder.group({
    name: new FormControl(this.contact.name, [Validators.required]),
    email: new FormControl(this.contact.email, [Validators.required]),
    phone: new FormControl(this.contact.phone, [Validators.required]),
    countryName: new FormControl(this.countryName),
    countryCode: new FormControl(this.countryCode),
    subject: new FormControl(this.contact.subject, [Validators.required]),
    message: new FormControl(this.contact.message, [Validators.required]),
  });
  contactSubmitted = false;

  constructor(
    private langService: LangService,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private geolocation: GeolocationService,
    private recaptchaService: RecaptchaService,
    private blogService: BlogService,
    private languageService: LanguageService,
    private metadataService: MetaDataService,
    private linkService: LinkService,
    private previousUrl: PreviousUrlService
  ) {
    this.um.user = {};
  }

  async ngOnInit() {
    // facebook SDK
    // this.facebookSDK();
    // google SDK
    // this.googleSDK();
    this.geolocation.getClientLocale().subscribe((data) => {
      this.userLanguage = data.language;
      this.userCountry = data.country;
      this.getRecentBlogs();
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.linkService.setLinkCanonical()
      }
    })
    await this.recaptchaService.loadRecaptcha();
    this.widgetId = grecaptcha.render("grecapt", {
      sitekey: environment.recaptcha,
      callback: this.onRecaptchaAppSuccess.bind(this),
      'expired-callback': this.onRecaptchaAppExpired.bind(this),
    });
    this.current_lang = await this.langService.loadLang();
    this._initMasterData();
    // console.log(data);
    // convert Map to array
    let count = 0;
    // for (let value of data.values()) {
    //   if (count > 3) break;
    //   this.blogs.push(value);
    //   count++;
    // }
  }

  onRecaptchaAppSuccess(response: string) {
    this.recaptchaResponse = response;
    (document.getElementById('form-submit-app') as HTMLButtonElement).disabled = false;
  }
  onRecaptchaAppExpired() {
    (document.getElementById('form-submit-app') as HTMLButtonElement).disabled = true;
  }

  // onBlogItemClick(slug) {
  //   this.router.navigateByUrl(`/blog/${slug}`);
  // }

  // one way binding need pass NgForm as parameter
  onSubmit(form: NgForm) {
    const lang = this.userLanguage;
    this.submitted = true;
    if (form.invalid) {
      return;
    }
    this.subscribe = form.value;
    this.apiService.sendSub2Slack(this.subscribe, lang);
    this.subscribe.email = '';
    this.submitted = false;
    form.resetForm();
  }

  // one way binding need pass NgForm as parameter
  onSubmitContact(contactForm: NgForm) {
    const lang = this.userLanguage;
    this.submitted = true;
    if (contactForm.invalid) {
      return;
    }
    if (!this.recaptchaResponse) {
      if (lang === 'en'){
        alert('Please complete captcha verification to submit your request.');
      } else {
        alert('Vui lòng hoàn thành Captcha để gửi yêu cầu của bạn.')
      }
      return;
    }
    this.contact = contactForm.value;
    this.contact.countryCode = this.contactForm.get('countryCode')!.value;
    this.contact.countryName = this.contactForm.get('countryName')!.value;
    this.apiService.sendContact2Slack(this.contact, lang);
    if (grecaptcha && grecaptcha.reset) {
      grecaptcha.reset(this.widgetId);
      this.submitted = false;
      this.contact = new Contact();
    }
    this.onRecaptchaAppExpired();
    contactForm.resetForm();
  }

  onActivate(event) {
    window.scrollTo(0, 0);
    // let scrollToTop = window.setInterval(() => {
    //   let pos = window.pageYOffset;
    //   if (pos > 0) {
    //     window.scrollTo(0, pos - 20); // how far to scroll on each step
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 10);
  }

  /**
   * Init data for first load
   */
  private async _initMasterData() {
    // this.httpClient.get("./assets/lang/en.json").subscribe(data =>{
    //   console.log(data);
    //   this.current_lang = data;
    // });
  }

  // // button login facebook
  // public async submitLoginFacebook() {
  //   this.loginService.submitLoginFacebook(true);

  // }

  // // button login google
  // public async submitLoginGoogle() {
  //   this.loginService.submitLoginGoogle(this.loginElement, true);

  // }

  // // SDK facebook library
  // facebookSDK() {
  //   this.loginService.facebookSDK();
  // }

  // // SDK google library
  // googleSDK() {
  //   this.loginService.googleSDK(this.loginElement, true);
  // }

  // // logout event
  // public logOut() {
  //   localStorage.removeItem("_u");
  //   // logout
  //   this.um.user = null;
  // }

  public showModalLogin() {
    this.userName.nativeElement.value = '';
    this.password.nativeElement.value = '';
    jQuery('#modalUser').modal('show');
  }

  // button login
  // public async onLoginSubmit(from) {
  //   await this.loginService.loginUser(from, true)
  // }
  public scrollToTop() {
    window.scrollTo(0, 0);
  }

  public changeLanguage(language: string, event: MouseEvent,) {
    // let href = window.location.href;
    const Url = '';
    this.languageService.changeLanguage(language, event,Url);
    // if (language == 'en') {
    //   href = href.replace('/vn/', `/${language}/`);
    // } else {
    //   href = href.replace('/en/', `/${language}/`);
    // }
    // // console.log('href replace:: ', href);
    // window.location.href = href;

    // console.log(window.location.href);     // Returns the href (URL) of the current page e.g: http://dicom-interactive.local/en/about-us/
    // console.log( window.location.hostname); // Returns the domain name of the web host e.g dicom-interactive.local
    // console.log( window.location.pathname); // Returns the path and filename of the current page e.g /en/about-us/
    // console.log( window.location.protocol); // Returns the web protocol used (http: or https:) e.g http:
    // console.log(window.location.assign);   // Loads a new document
    // console.log(window.location.replace);  // Replace the current location with new one.
  }

  async getRecentBlogs(): Promise<void> {
    try {
      const lang = this.userLanguage;
      this.blogs = await this.blogService.getBlogRecent(lang).toPromise();
      this.blogs.forEach((blog) => {
        let timestamp = blog.createAt;
        if (typeof timestamp === 'string') {
          timestamp = parseInt(timestamp);
        }
        if (typeof timestamp === 'number' && !isNaN(timestamp)) {
          const dateObj = new Date(timestamp * 1000);
          const options:{
            year?: "numeric" | "2-digit" | undefined;
            month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
            day?: "numeric" | "2-digit" | undefined;
          } = { year: 'numeric', month: 'short', day: 'numeric' };
          blog.formattedDate = dateObj.toLocaleDateString('en-US', options);
        } else {
        }
      });
    } catch (error) {}
  }

  incrementView(blogId: string) {
    this.blogService.incrementViewCount(blogId).subscribe((response) => {
      // Update the view count in the UI
      const blogToUpdate = this.blogs.find(
        (blog: { _id: string }) => blog._id === blogId
      );
      if (blogToUpdate) {
        blogToUpdate.views = response.updatedViewCount;
      }
    });
  }

  onBlogItemClick(slug) {
    this.router.navigateByUrl(`/blog/${slug}`);
  }
}
