<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>Ucademy Corporate Site & B2B SaaS Platform</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images/portfolio/image-15.jpg" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span i18n>About The Project</span>
          <h2 i18n>SaaS Platform For Immersive e-Learning Experiences</h2>
          <p i18n>Our SaaS B2B platform is a powerful LMS that lets businesses create an online school for
            their employees, students, or customers. Users can easily create courses, manage students,
            and classroom activities within the platform.</p>
          <p i18n>This LMS is a comprehensive solution for online learning, with a user-friendly interface and
            customizable features. It's perfect for businesses investing in their staff or customer's
            professional development.</p>
          <p><strong> ReactJS | NodeJS | Stripe | Figma | Redis | MongoDB | AWS </strong></p>
        </div>
        <div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow">
            <a href="https://ucademy.io/" target="blank">
              <img src="assets/images/icons/www.png" alt="visit"></a>
          </div>
          <div><strong> ⤺ </strong><span style="font-size: smaller;" i18n>Click here to visit the site</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span i18n>Service Features</span>
          <h2 class="mb30" i18n>Some of the key features of the platform include:
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Course management</h4>
        <ul class="list-style-">
          <li i18n>Ucademy provides a comprehensive set of tools for managing and delivering online courses.
            You can easily create and organize course content, including video lectures, quizzes,
            surveys, and exams. You can also set up prerequisites, grading policies, and completion
            criteria to ensure that learners progress through the material in a structured way.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>User and corporate management</h4>
        <ul class="list-style-">
          <li i18n>Ucademy makes it easy to manage individual learners and corporate clients. You can create
            and manage user accounts, assign learners to specific courses and learning paths, and track
            their progress as they work through the material. You can also set up custom user roles and
            permissions, allowing you to control access to specific features and content.</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Classroom management</h4>
        <ul class="list-style-">
          <li i18n>virtual classroom tool allows you to create a collaborative learning environment, complete
            with a whiteboard for shared note-taking, chat functionality, and the ability to take
            attendance. This tool can be particularly useful for synchronous learning events, such as
            live webinars or virtual workshops.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10" i18n>Marketing tools</h4>
        <ul class="list-style-" i18n>
          <li>Ucademy includes a range of marketing tools to help you promote your courses and grow your
            business. You can create custom email templates to reach out to potential customers, set up
            online-to-offline payment processing, create alumni programs to incentivize repeat business,
            and even offer referral rewards to encourage learners to refer their friends and colleagues.
            You can also create bundles and coupons to help increase sales.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20" i18n>Challenges</h2>
        <p i18n>Building Ucademy has been an exciting journey so far, but it hasn't come without its
          challenges.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Scalability has been a major concern for us as we strive to
          accommodate a growing user base and course library. To address this, we've invested in cloud
          infrastructure and load balancing to ensure the platform can handle traffic spikes.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Security is also a top priority for us, and we've implemented robust
          security measures like encryption and access controls to protect user data and prevent
          unauthorized access. We're constantly monitoring the platform for vulnerabilities and working to
          address them promptly.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Ensuring a user-friendly interface that meets the needs of
          instructors and learners has been another challenge. We've engaged in extensive user testing to
          improve the platform's design and functionality, and we're working closely with our community to
          gather feedback and make adjustments.<br><br></p>
        <p style="text-indent: 15px;" i18n>➟ Finally, integrating with third-party tools and services has been an
          ongoing challenge, but we recognize its importance for our users. We've worked closely with our
          partners to ensure seamless integration with a range of services like payment processors and
          learning management systems.<br><br></p>
        <p i18n>We remain committed to continuously improving Ucademy and meeting the needs of our community of
          instructors and learners.</p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images/case-study/goen-view.png" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span i18n>Solutions</span>
          <h2 i18n>How We Work</h2>
          <h4 i18n>Here are a few important measures that we may have implemented during the course of
            development.<br><br></h4>
          <p i18n>Conducted thorough research to determine the needs and requirements of our target market, and
            used this information to guide the development process. This research may have included
            identifying customer pain points, assessing competitor offerings, and determining what
            features and functionality would be most valuable to our target audience.</p>
          <p i18n>Developed core features such as course management, user management, analytics, and reporting,
            as well as APIs for third-party integration. We may have leveraged existing frameworks or
            built these features from scratch, depending on our needs and available resources.
          </p>
          <p i18n>Ensured that the LMS is mobile-friendly, responsive, and accessible on a variety of devices
            and browsers. This involved designing and testing the user interface to ensure that it was
            optimized for different screen sizes and input methods, as well as ensuring that the backend
            infrastructure was able to handle requests from a variety of devices and platforms.
          </p>
          <p i18n>Implemented strong security measures to protect user data, including encryption, access
            controls, and threat detection. This may have included working with cybersecurity experts to
            identify potential vulnerabilities and implementing best practices for secure data handling
            and storage.</p>
          <p i18n>Continuously monitored the LMS for performance and user feedback, and made necessary updates
            and improvements based on this information. This involved setting up monitoring and
            analytics tools to track system performance and user engagement, as well as soliciting
            feedback from customers and stakeholders to identify areas for improvement.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
              <h4>Back-end/API Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/price.svg" alt="case study" class="img-fluid">
              <h4>Stripe Payment Gateway Integration</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/ucademy-web-1.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/ucademy-web-2.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/ucademy-web-3.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/ucademy-web-4.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!--Start Navigate-->
  <section class="testinomial-section pad-tb">
    <div class="container">
      <div class="row">
        <div class="col-6 v-center">
          <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
        </div>
        <div class="col-6 v-center">
          <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
        </div>
      </div>
    </div>
  </section>
  <!--End Navigate-->

  <!--Start Testinomial-->
  <app-testinomial-component></app-testinomial-component>
  <!--End Testinomial-->
