<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-11" data-background="assets/images/banner/11.jpg">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 v-center">
					<div class="bread-inner">
						<div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
							<ul>
								<li><a [routerLink]="['/']" i18n>Home</a></li>
								<li><span i18n>Contact</span></li>
							</ul>
						</div>
						<div class="bread-title wow fadeInUp" data-wow-delay=".5s">
							<h1 i18n>Contact Us</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Breadcrumb Area-->

<!--Start Enquire Form-->
<section class="contact-page pad-tb">
	<div id="contact" class="container">
		<div class="justify-content-center col-md-10">
			<div class="v-center">
				<div class="common-heading text-l">
					<span i18n>Contact Now</span>
					<!-- <h2 class="mt0 mb0 from-span" *ngIf="selectedSubject !== 'web' && currentLanguage === 'en'" i18n>For any enquiries or to say hello, get in touch 👋</h2> -->
					<!-- <h2 class="mt0 mb0" *ngIf="selectedSubject !== 'web'" [ngClass]="{'': currentLanguage === 'en', 'from-span': currentLanguage === 'vn'}" i18n>For any enquiries or to say hello, get in touch 👋</h2>
					<p class="mb60 mt20" *ngIf="selectedSubject!== 'web'" i18n>We will catch you as early as we receive the message</p> -->
					<h2 class="mt0 mb0 from-span" i18n>Get Your Free Quote Today!</h2>
					<p class="mb60 mt20" i18n>Tell us about your project, and we'll provide a customized quote.</p>
				</div>
				<div class="form-block color">
					<form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)" id="contactForm"
						data-toggle="validator" class="shake">
						<div class="row-from row">
							<div class="form-group col-sm-6 input-mobile">
								<input type="text" name="name" id="name" [ngModel]="quote.name" #name="ngModel"
									i18n-placeholder placeholder="Enter name" required
									oninput="this.value = this.value.replace(/[\s]+/g, ' ',)" minlength="6">
								<div *ngIf="submitted || (name.invalid && (name.dirty || name.touched))">
									<div *ngIf="name.errors?.required" class="help-block with-errors" i18n>Name is required</div>
									<div *ngIf="name.errors?.minlength" class="help-block with-errors" i18n>Name must be at least 6 characters</div>
								</div>
							</div>
							<div class="form-group col-sm-6 input-mobile">
								<input type="email" id="email" name="email" [ngModel]="quote.email" #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
									i18n-placeholder placeholder="Enter email" required email>
								<div *ngIf="submitted || (email.invalid && (email.dirty || email.touched))">
									<div *ngIf="email.errors?.required" class="help-block with-errors" i18n>Email is required</div>
									<div *ngIf="email.errors?.pattern" class="help-block with-errors" i18n>Email is invalid</div>
								</div>
							</div>
						</div>
						<div class="row-from row">
							<div class="form-group col-sm-6 phone input-mobile">
								<input type="text" class="input-mobile" id="mobile" name="phone" id="phone" [ngModel]="quote.phone" ng2TelInput
								  [ng2TelInputOptions]="{'preferredCountries': ['au', 'us', 'de', 'vn']}" required minlength="6" maxlength="12"
								  [minlength]="phone.errors?.pattern ? null : 6" [maxlength]="phone.errors?.pattern ? null : 12"
								  pattern="\d+" (countryChange)="onCountryChange($event)" #phone="ngModel" placeholder="Enter mobile" i18n-placeholder required data-error="Please fill Out">
								<div *ngIf="submitted || (phone.invalid && (phone.dirty || phone.touched))">
								  <div *ngIf="phone.errors?.required" class="help-block with-phone" i18n>Mobile is required</div>
								  <div *ngIf="phone.errors?.minlength || phone.errors?.maxlength" class="help-block with-phone" i18n>Mobile number must be between 6 and 12 digits</div>
								  <div *ngIf="phone.errors?.pattern" class="help-block with-phone" i18n>Invalid Mobile Number</div>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<select id="Dtype" name="subject" id="subject" [ngModel]="quote.subject" (change)="resetErrors(contactForm)" (ngModelChange)="selectedSubject = $event"
								 #subject="ngModel" required>
									<option selected disabled value="" i18n>Select Requirement</option>
									<option value="web" i18n>Web Development</option>
									<option value="app" i18n>Mobile App Development</option>
									<option value="graphic" i18n>Graphic Design</option>
									<option value="marketing" i18n>Digital Marketing</option>
									<option value="resource">Dedicated Staff</option>
									<option value="other" i18n>Other</option>
								</select>
								<div *ngIf="submitted || (subject.invalid && (subject.dirty || subject.touched))">
									<div *ngIf="subject.errors?.required" class="help-block with-errors" i18n>Subject is required</div>
								</div>
							</div>
						</div>
						<div class="row-from row" *ngIf="selectedSubject !== 'web'">
							<div class="form-group col-sm-12">
								<textarea id="message" rows="3" name="message" id="message" [ngModel]="quote.message"
									#message="ngModel" placeholder="Enter your message" i18n-placeholder required></textarea>
								<div *ngIf="submitted || (message.invalid && (message.dirty || message.touched))">
									<div *ngIf="message.errors?.required" class="help-block with-errors" i18n>Message is required</div>
								</div>
							</div>
						</div>
						<div class="row" *ngIf="selectedSubject === 'web'">
							<div class="col-md-12 form-group">
								<input type="text" id="companyName" name="companyName" #companyName="ngModel"
									[ngModel]="quote.message" placeholder="Enter your company"
									data-error="Please fill Out" i18n-placeholder>
								</div>
							<div class="form-group form-title">
								<div class="col-md-12 form-group">
									<div class="group-tile"><p i18n>When is your target date for launching your new website?</p></div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="launching1" name="deadline" [value]="0" [(ngModel)]="quote.deadline" required>
										<label class="custom-control-label" for="launching1" i18n>Within a month</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="launching2" name="deadline" [value]="1" [(ngModel)]="quote.deadline" required>
										<label class="custom-control-label" for="launching2" i18n>1-2 months</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="launching3" name="deadline" [value]="2" [(ngModel)]="quote.deadline" required>
										<label class="custom-control-label" for="launching3" i18n>+2 months</label>
									</div>
									<div *ngIf="submitted">
										<div *ngIf="!quote.deadline && quote.deadline != '0'"
											class="help-block with-web" i18n>Please select options above</div>
									</div>
								</div>
							</div>
							<div class="form-group form-title">
								<div class="col-md-12 form-group">
									<div class="group-tile"><p i18n>Are you planning to supply any content, such as text, images, or logo files?</p></div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="contentPlan1" name="contentPlan" [value]="0" [(ngModel)]="quote.contentPlan" required>
										<label class="custom-control-label" for="contentPlan1" i18n>Absolutely, all of them</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="contentPlan2" name="contentPlan" [value]="1" [(ngModel)]="quote.contentPlan" required>
										<label class="custom-control-label" for="contentPlan2" i18n>Just some text and imagery</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="contentPlan3" name="contentPlan" [value]="2" [(ngModel)]="quote.contentPlan" required>
										<label class="custom-control-label" for="contentPlan3" i18n>Not at all</label>
									</div>
									<div *ngIf="submitted">
										<div *ngIf="!quote.contentPlan && quote.contentPlan != '0'"
											class="help-block with-web" i18n>Please select options above</div>
									</div>
								</div>
							</div>
							<div class="form-group form-title">
								<div class="col-md-12 form-group">
									<div class="group-tile" i18n>How many pages will your website need?</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="pageRequest1" name="pageRequest" [value]="0" [(ngModel)]="quote.pageRequest" required>
										<label class="custom-control-label" for="pageRequest1" i18n>One page</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="pageRequest2" name="pageRequest" [value]="1" [(ngModel)]="quote.pageRequest" required>
										<label class="custom-control-label" for="pageRequest2" i18n>Up to 5</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="pageRequest3" name="pageRequest" [value]="2" [(ngModel)]="quote.pageRequest" required>
										<label class="custom-control-label" for="pageRequest3" i18n>Up to 10</label>
									</div>
									<div class="custom-control custom-radio">
										<input type="radio" class="custom-control-input" id="pageRequest4" name="pageRequest" [value]="3" [(ngModel)]="quote.pageRequest" required>
										<label class="custom-control-label" for="pageRequest4" i18n>I'm not sure</label>
									</div>
									<div *ngIf="submitted">
										<div *ngIf="!quote.pageRequest && quote.pageRequest != '0'"
											class="help-block with-web" i18n>Please select options above</div>
									</div>
								</div>
							</div>
							<div class="form-group form-title">
								<div class="col-md-12 form-group">
									<div class="group-tile"><p i18n>Which particular functionalities or features are you interested in having on the website?</p></div>
									<div class="custom-control custom-radio custom-control-inline">
										<input type="radio" class="custom-control-input" id="features1" name="featureRequest" [value]="0" [(ngModel)]="quote.featureRequest" required>
										<label class="custom-control-label" for="features1" i18n>I want to show my business information, its products and services</label>
									</div>
									<br>
									<div class="custom-control custom-radio custom-control-inline custom-title">
										<input type="radio" class="custom-control-input" id="features2" name="featureRequest" [value]="1" [(ngModel)]="quote.featureRequest" required>
										<label class="custom-control-label" for="features2" i18n>I want to sell product online with shopping cart and checkout process</label>
									</div>
									<br>
									<div class="custom-control custom-radio custom-control-inline custom-title">
										<input type="radio" class="custom-control-input" id="features3" name="featureRequest" [value]="2" [(ngModel)]="quote.featureRequest" required>
										<label class="custom-control-label" for="features3" i18n>I want to have a showcase of work, projects, or personal achievements</label>
									</div>
									<br>
									<div class="custom-control custom-radio custom-control-inline custom-title">
										<input type="radio" class="custom-control-input" id="features4" name="featureRequest" [value]="3" [(ngModel)]="quote.featureRequest" required>
										<label class="custom-control-label" for="features4" i18n>I want to have a blog</label>
									</div>
									<br>
									<div class="custom-control custom-radio custom-control-inline custom-title">
										<input type="radio" class="custom-control-input" id="features5" name="featureRequest" [value]="4" [(ngModel)]="quote.featureRequest" required>
										<label class="custom-control-label" for="features5" i18n>Other</label>
									</div>
									<div *ngIf="submitted">
										<div *ngIf="!quote.featureRequest && quote.featureRequest != '0'" class="help-block with-web" i18n>Please select options above</div>
									</div>
								</div>
							</div>
							<div class="fieldsets form-group col-md-12">
								<textarea type="text" id="message" name="message" [ngModel]="quote.message" #message="ngModel" placeholder="Feel free to describe your business and its core activities *" required data-error="Please fill Out" rows="3" i18n-placeholder></textarea>
								<div *ngIf="submitted || (message.invalid && (message.dirty || message.touched))">
									<div *ngIf="message.errors?.required" class="help-block with-errors" i18n>Message is required</div>
								</div>
							</div>
							<div class="fieldsets- row col-md-12">
								<div class="col-md-12 form-group">
								  <div class="custom-file">
									<input type="file" id="files" (click)="fileInput.value = null" name="files" #fileInput (click)="fileInput.value = null" [ngModel]="quote.files" #files="ngModel" placeholder="Project briefs, design mockup ..."  accept=".pdf, .doc, .docx" (change)="handleFileInput($event.target.files)" multiple class="custom-file-input mb0 cursor-pointer">
									<label class="custom-file-label" for="files" i18n>Project briefs, design mockup ...</label>
								  </div>
									<div *ngIf="fileInputError" class="help-block with-errors" style="margin-top: 16px;">{{fileInputError}}</div>
									  <!-- file name -->
									<div *ngIf="fileChose1 && !fileInputError" style="margin-top: 10px;"><small class="help-block with-messages">1. {{fileChose1}}</small></div>
									<div *ngIf="fileChose2 && !fileInputError"><small class="help-block with-messages">2.{{fileChose2}}</small></div>
								  	<p><small i18n>Please upload maximum 2 files Only pdf, docx and doc files.</small></p>
								</div>
							  </div>
						</div>
						<div class="form-group from-recaptcha col-md-12">
							<div class="g-recaptcha" ng-model="g-recaptcha-response" id="recaptcha"></div>
						</div>
						<button *ngIf="selectedSubject !== 'web'" type="submit" id="form-submit" class="btn lnk btn-main bg-btn" [disabled]="!submitted" i18n>Submit<span class="circle"></span></button>
						<button *ngIf="selectedSubject === 'web'" type="submit" id="form-submit" class="btn lnk btn-main bg-btn" [disabled]="!submitted" i18n>Get Free Quote<span class="circle"></span></button>
						<div id="msgSubmit" class="h3 text-center hidden from-submit"></div>
						<span class="free-quote-confident" *ngIf="selectedSubject === 'web'" i18n>
							<p>💙 We respect your privacy and will only use the information you provide to prepare your customized quote.</p>
							<p>Your data is secure and will not be shared with third parties.</p></span>
						<div class="clearfix"></div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>




<section class="our-office pad-tb">
	<div class="container">
		<div class="row row-contact justify-content-center upset shape-numm">
			<div class="col-lg-4 col-sm-6 shape-loc mt30 wow fadeIn col-lg-contact" data-wow-delay=".2s">
				<div class="contact-card info-card info-card-contact">
					<span i18n><i class="fas fa-phone-alt"></i> Phone:</span>
					<div class="info-body info-body-contact">
						<p i18n>Assistance hours: Monday – Friday, 9AM to 6PM</p>
						<!-- <p>Steven Quach: <a href="tel:+61402752523">(+61) 402 7525 23</a></p>
							<p>Daniel Le: <a href="tel:+84907506614">(+84) 907 5066 14</a></p>
							<p>Suzanne Nguyen: <a href="tel:+84932762016">(+84) 932 7620 16</a></p> -->
						<p> Hotline: <a href="tel:+61390187886"> (+61) 390 1878 86</a></p>
						<p> Hotline: <a href="tel:+842871065144"> (+84) 287 1065 144</a></p>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 shape-loc mt30 wow fadeIn col-lg-contact" data-wow-delay=".5s">
				<div class="email-card info-card info-card-contact">
					<span><i class="fas fa-envelope"></i> Email:</span>
					<div class="info-body info-body-contact">
						<p i18n>Our support team will get back to in 24-h during standard business hours.</p>
						<p><a href="mailto:sales@dicom-interactive.com">sales@dicom-interactive.com</a></p>

					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 shape-loc mt30 wow fadeIn col-lg-contact" data-wow-delay=".8s">
				<div class="skype-card info-card info-card-contact">
					<span><i class="fab fa-whatsapp-square"></i> Whatsapp:</span>

					<div class="info-body info-body-contact">
						<p i18n>We Are Online: Monday – Friday, 9 am to 5 pm</p>
						<p><a href="whatsapp://send?text=Hello&phone=+84907506614"> Dicom Interactive</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Enquire Form-->
<!--Start Location-->
<section class="our-office pad-tb">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div class="common-heading">
					<span i18n>Our Locations</span>
					<h2 i18n>Our offices</h2>
				</div>
			</div>
		</div>
		<div class="row justify-content-center upset shape-numm">
			<div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".2s">
				<div class="office-card">
					<div class="skyline-img">
						<img src="assets/images/location/newyork.webp" alt="Saigon" class="img-fluid" />
					</div>
					<div class="office-text">
						<h4>Saigon</h4>
						<p>81 CMT8 st, Bến Thành ward, dist 1, Hochiminh city, Vietnam.</p>
						<a href="https://goo.gl/maps/wRttxVknzVHBoDjaA" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-map-marker-alt"></i></a>
						<a href="tel:+842871065144" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-phone-alt"></i></a>
						<a href="mailto:sales@dicom-interactive.com" target="blank" class="btn-outline rount-btn"><i
								class="fas fa-envelope"></i></a>
						<a href="whatsapp://send?text=Hello&phone=+842871065144" target="blank"
							class="btn-outline rount-btn"><i class="fas fa-comment-dots"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".5s">
				<div class="office-card">
					<div class="skyline-img">
						<img src="assets/images/location/sydeny.webp" alt="Melbourne" class="img-fluid" />
					</div>
					<div class="office-text">
						<h4>Melbourne</h4>
						<p class="mb48">18 Dennis St, Lalor VIC 3075, Australia.</p>
						<a href="https://goo.gl/maps/cg2wjvwDss7RQxAx5" target="blank" class="btn-outline rount-btn">
							<i class="fas fa-map-marker-alt"></i></a>
						<a href="tel:+61390187886" target="blank" class="btn-outline rount-btn">
							<i class="fas fa-phone-alt"></i></a>
						<a href="mailto:sales@dicom-interactive.com" target="blank" class="btn-outline rount-btn">
							<i class="fas fa-envelope"></i></a>
						<a href="whatsapp://send?text=Hello&phone=+61390187886" target="blank"
							class="btn-outline rount-btn"><i class="fas fa-comment-dots"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".8s">
				<div class="office-card mb0">
					<div class="skyline-img">
						<img src="assets/images/location/rome.webp" alt="Brussels" class="img-fluid" />
					</div>
					<div class="office-text">
						<h4>Brussels</h4>
						<p i18n>Establishing.</p>
						<!--a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fas fa-map-marker-alt"></i></a>
<a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fas fa-phone-alt"></i></a>
<a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fas fa-envelope"></i></a>
<a href="javascript:void(0)" target="blank" class="btn-outline rount-btn"><i class="fab fa-skype"></i></a-->
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Location-->
<!--Start CTA-->
<section class="cta-area pad-tb">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div class="common-heading">
					<span i18n>Let's work together</span>
					<h2 i18n>We Love to Listen to Your Requirements</h2>
					<a href="contact/#contact" class="btn-outline" i18n>Estimate Project <i
							class="fas fa-chevron-right fa-icon"></i></a>
					<p class="cta-call" i18n>Or call us now <a href="tel:+61390187886"><i class="fas fa-phone-alt"></i>
							(+61) 390 1878 86</a> or <a href="tel:+842871065144"><i class="fas fa-phone-alt"></i>
							(+84) 287 1065 144</a></p>
				</div>
			</div>
		</div>
	</div>
	<div class="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
	<div class="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
	<div class="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
	<div class="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
</section>
<!--End CTA-->
