import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
// Self
import { ScriptService, MetaDataService } from '../../services';
import {
  RICH_BANNERS,
  SERVICES_BANNER,
  STANDARD_BANNERS,
  TECH_STACK_BANNER,
} from './data';
declare var jQuery: any;

@Component({
  selector: 'app-services-media-banners',
  templateUrl: './media-banners.component.html',
  styleUrls: ['./media-banners.component.css'],
})
export class MediaBannersComponent extends MetaDataService implements OnInit {
  isScriptLoaded = false;
  rich_banners = RICH_BANNERS;
  standard_banners = STANDARD_BANNERS;
  services = SERVICES_BANNER;
  techStack = TECH_STACK_BANNER;
  selectorBanner = null;

  mounted = false;

  constructor(
    private scriptService: ScriptService,
    private route: ActivatedRoute,
    private router: Router,
    title: Title,
    meta: Meta,
    private renderer: Renderer2
  ) {
    super(title, meta);

    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isScriptLoaded = true;
    });
  }

  ngOnInit() {
    this.setMetaTags({
      description:
        'Explore Dicom Interactive’s professional web design services, offering customizable, SEO-friendly solutions for all industries, helping you create websites quickly and effectively.',
      image: 'https://dicom-interactive.com/en/assets/images/logo_small.png',
      publisher: 'Dicom Interactive',
      author: 'Dicom Interactive',
      title: 'Media Banners | Dicom Interactive',
      url: 'https://www.dicom-interactive.com/en/services/media-banners/',
      siteName: 'Media Banners | Dicom Interactive',
      twitterCreator: '@dicomInteractive',
      keywords: [
        'web design services',
        'SEO services',
        'website solutions',
        'professional web design',
        'SEO optimization',
        'web development services',
        'media banners',
      ],
      canonical: 'https://www.dicom-interactive.com/en/services/media-banners/',
    });

    this.route.paramMap.subscribe((params) => {
      const slug = params.get('slug');
      if (slug) {
        const isValidSlug = this.rich_banners.some(
          (item) => item.slug === slug
        );
        const indexStandardBanner = this.standard_banners.findIndex(
          (item) => item.slug === slug
        );
        if (isValidSlug) {
          this.selectTab(slug);
          this.scrollTo(event);
        } else if (indexStandardBanner > -1) {
          this.scrollToBanner(event, slug, indexStandardBanner)
          this.showBanner(0);
        } else {
          this.navigateToFirstBanner();
        }
      } else {
        this.showBanner(0);
      }
    });

    const script = this.renderer.createElement('script');
    script.src = 'https://dicom-interactive.com/banners/dic.js';
    script.type = 'text/javascript';
    this.renderer.appendChild(document.body, script);
  }

  // ngAfterViewInit() {
  //   const slug = this.route.snapshot.paramMap.get('slug');
  //   if (slug && this.banners.some((item) => item.slug === slug) && typeof window !== 'undefined') {
  //     this.scrollTo(window.event);
  //   }
  // }

  scrollTo(event: Event) {
    event.preventDefault();

    setTimeout(() => {
      const target = document.querySelector<HTMLAnchorElement>(
        '.service.banners .tabs-layout.banners .nav-tabs .nav-item .nav-link'
      );
      const header = document.querySelector('header');
      const headerHeight = header?.offsetHeight || 0;

      if (target) {
        const targetPosition = target.offsetTop - (headerHeight + 10);
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      }
    }, 0);
  }
  
  private setSelectorBanner (index: number) {
    if (index < 0 || index >= this.standard_banners.length) {
      this.selectorBanner = null;
      return;
    }
    this.selectorBanner = this.standard_banners[index];
  }
  
  scrollToBanner(event: Event, slug: string, index: number) {
    event.preventDefault();
    this.setSelectorBanner(index);

    setTimeout(() => {
      const target = document.querySelector<HTMLAnchorElement>(
        `#${slug}`
      );
      const header = document.querySelector('header');
      const headerHeight = header?.offsetHeight || 0;

      if (target) {
        const targetPosition = target.getBoundingClientRect().top + window.scrollY - (headerHeight + 10);
        console.log(targetPosition);
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      }
    }, 0);
    
    setTimeout(() => {
      jQuery('#modalform').modal('show');
    }, 0);
  }
  
  closeBanner() {
    jQuery('#modalform').addClass('fade');
    jQuery('#modalform').modal('hide');
  }

  private navigateToFirstBanner() {
    this.router.navigate([
      `/services/media-banners/${this.rich_banners[0].slug}`,
    ]);
    this.showBanner(0);
  }

  private showBanner(index: number) {
    this.rich_banners = this.rich_banners.map((banner, i) => ({
      ...banner,
      show: i === index,
    }));
  }

  selectTab(slug: string) {
    this.rich_banners = this.rich_banners.map((banner) => ({
      ...banner,
      show: banner.slug === slug,
    }));
    const iframe = document.querySelector(`.${slug}`) as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      setTimeout(() => {
        iframe.contentWindow.location.reload();
      }, 0);
    }
  }
}
