// core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecaptchaService } from './services/recaptcha.service';
// 3rd
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
//create
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PortfolioComponent } from './portfolios/portfolios.component';
import { Ask2GoComponent } from './project/ask2go.component';
import { GoenComponent } from './project/goen.component';
import { EnvocaComponent } from './project/envoca.component';
import { MeotoComponent } from './project/meoto.component';
import { HyundaiI30BannerComponent } from './project/hyundai-i30-banner.component';
import { AscentisHomeDesignComponent } from './project/ascentis-home-design.component';
import { HyundaiI20BannerComponent } from './project/hyundai-i20-banner.component';
import { CulesComponent } from './project/cules.component';
import { FresyComponent } from './project/fresy.component';

import { ReviewsComponent } from './component/review/gg-review.component';

import { DigitalMarketingComponent } from './dicom-service-detail/digital-marketing.component';
import { GraphicDesignComponent } from './dicom-service-detail/graphic-design.component';
import { MobileDevelopmentComponent } from './dicom-service-detail/mobile-development.component';
import { WebDevelopmentComponent } from './dicom-service-detail/web-development.component';
import { OffshoreDevelopmentCenterComponent } from './dicom-service-detail/offshore-development-center.component';

import { AboutDicomComponent } from './about-us/about-dicom.component';
import { AboutTeamComponent } from './about-us/about-team.component';
import { AboutDicomAusComponent } from './about-us/about-dicom-aus.component';
import { BlogComponent } from './blog/blog.component';
import { CounterComponent } from './dicom-service-detail/counter.component';
import { WhyUsComponent } from './dicom-service-detail/why-us.component';
import { BlogSingleComponent } from './blog/blog-single.component';
import { CareerComponent } from './career/career.component';
import { ContactComponent } from './contact/contact.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UcademyPlatformComponent } from './project/ucademy-platform.component';
import { MmhfComponent } from './project/mmhf.component';
import { KmmtVendorComponent } from './project/kmmt-vendor.component';
import { WhipEvComponent } from './project/whipev.component';
import { TdholdingComponent } from './project/tdholding.component';
import { MovinavirComponent } from './project/movinavir.component';
import { TestinomialComponent } from './component/testinomial/testinomial.component';
import { KeazComponent } from './project/keaz.component';
import { WhiteSpaAndNailComponent } from './project/white-spa-and-nail.component';
import { WildImportsComponent } from './project/wild-imports.component';
import { NotFoundComponent } from './notfound/notfound.component';
// import { ToastrModule } from 'ngx-toastr';
// import { APP_BASE_HREF } from '@angular/common';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ShowCaseDetailComponent, ShowcaseComponent } from './showcase';
import { CollectionComponent } from './collection/collection.component';
import { CollectionSingleComponent } from './collection/collection-single.component';
import { VndCurrencyPipe } from './vnd-currency.pipe';
import { SafePipe } from './safe.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import localeVi from '@angular/common/locales/vi';
import { MediaBannersComponent } from './dicom-service-detail/media-banners/media-banners.component';

registerLocaleData(localeVi);
@NgModule({
  declarations: [
    ShowcaseComponent,
    ShowCaseDetailComponent,
    NotFoundComponent,
    AppComponent,
    HomeComponent,
    PortfolioComponent,
    UcademyPlatformComponent,
    MmhfComponent,
    KmmtVendorComponent,
    WhipEvComponent,
    TdholdingComponent,
    MovinavirComponent,
    Ask2GoComponent,
    GoenComponent,
    EnvocaComponent,
    MeotoComponent,
    HyundaiI30BannerComponent,
    AscentisHomeDesignComponent,
    HyundaiI20BannerComponent,
    CulesComponent,
    FresyComponent,
    KeazComponent,
    WhiteSpaAndNailComponent,
    WildImportsComponent,
    AboutDicomComponent,
    AboutTeamComponent,
    AboutDicomAusComponent,
    BlogComponent,
    BlogSingleComponent,
    DigitalMarketingComponent,
    GraphicDesignComponent,
    MobileDevelopmentComponent,
    WebDevelopmentComponent,
    MediaBannersComponent,
    OffshoreDevelopmentCenterComponent,
    CareerComponent,
    ContactComponent,
    CounterComponent,
    WhyUsComponent,
    ReviewsComponent,
    TestinomialComponent,
    CollectionComponent,
    CollectionSingleComponent,
    VndCurrencyPipe,
    SafePipe,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    Ng2TelInputModule,
    InfiniteScrollModule,
    // ToastrModule.forRoot(),
  ],
  // NO NEED because we have added <base href="/"> in index.html
  // providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  providers: [
    {
      provide: 'isBrowser',
      useValue: true,
    },
    RecaptchaService,
  ],
  // providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
