<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-12" data-background="assets/images/banner/12.jpg">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 v-center">
					<div class="bread-inner">
						<div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
							<ul>
								<li><a [routerLink]="['/']" i18n>Home</a></li>
								<li><span>Blog</span></li>
							</ul>
						</div>
						<div class="bread-title wow fadeInUp" data-wow-delay=".5s">
							<h1>Blog</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Breadcrumb Area-->
<!--Start Blog Grid-->
<!-- blog -->
<!-- <div *ngFor="let blog of blogs" class="col-md-4" (click)="onBlogItemClick(blog.slug)">
					<div class="blog">
						<div class="blog-img">
							<img class="img-responsive" [src]="blog.image" [alt]="blog.slug" [title]="blog.slug">
						</div>
						<div class="blog-content">
							<ul class="blog-meta">
								<li><i class="fa fa-user"></i>{{blog.createBy}}</li>
								<li><i class="fa fa-clock-o"></i>{{blog.createAt}}</li>
								<li><i class="fa fa-eye"></i>{{blog.readCount}}</li>
							</ul>
							<h3>{{blog.title}}</h3>
							<p>{{blog.excerpt}}</p>
							<a [routerLink]="['/', 'blog', blog.slug]">Read more</a>
						</div>
					</div>
				</div> -->
<!-- /Row -->
<!--  -->
<!--End Blog Grid-->
<section class="blog-page pad-tb pt40">
	<div class="container">
	  <div class="row">
		<div *ngFor="let blog of blogs" class="col-md-4" class="col-lg-4 col-sm-6 single-card-item">
		  <div class="isotope_item hover-scale">
			<div class="item-image">
			  <a [routerLink]="['/blog', blog.urlBlog]" (click)="incrementView(blog._id)">
				<img [src]="blog.imageUrl" [alt]="blog.urlBlog" [title]="blog.urlBlog" class="img-fluid"/>
			  </a>
			  <span class="category-blog">
				<a *ngFor="let cat of blog.category" [routerLink]="['/blog', '']" (click)="incrementView(blog._id)">{{ cat }}</a>
			  </span>
			</div>
			<div class="item-info blog-info">
			  <div class="entry-blog">
				<span class="bypost">
				  <a [routerLink]="['/blog', blog.urlBlog]" (click)="incrementView(blog._id)"><i class="fas fa-user"></i>{{ blog.author }}</a>
				</span>
				<span class="posted-on">
					<a [routerLink]="['/', 'blog', blog.urlBlog]">
					  <i class="fas fa-clock"></i> {{ blog.formattedDate}}
					</a>
				  </span>
				<span>
				  <a [routerLink]="['/', 'blog', blog.urlBlog]" (click)="incrementView(blog._id)"><i class="far fa-eye"></i> {{ blog.view }}</a>
				</span>
			  </div>
			  <h4><a [routerLink]="['/', 'blog', blog.urlBlog]" (click)="incrementView(blog._id)">{{blog.title}}</a></h4>
			  <p class="text-blog-single">{{blog.introduce}}</p>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </section>

<!--End Blog Grid-->
