// language.service.ts

import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export abstract class MetaDataService {
  constructor(private title: Title, private meta: Meta) {}

  updateTags(
    tag: string,
    description: string,
    partUrl?: string,
    thumbnailUrl?: string
  ) {
    if (!tag) {
      tag = 'Home';
    }

    this.updateMeta(tag, description);
    this.updateUrl(partUrl, thumbnailUrl);
  }

  noIndex(){
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' })
  }

  private updateMeta(title: string, description) {
    this.title.setTitle(title + ' | Dicom Interactive');
    // this.meta.updateTag({ name: "og:site_name", content: title })
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ property: 'og:description', content: description });
      this.meta.updateTag({
        name: 'twitter:description',
        content: description,
      });
    }
  }

  private updateUrl(partUrl: string, thumbnail: string) {
    if (partUrl) {
      this.meta.updateTag({ property: 'og:url', content: partUrl });
      this.meta.updateTag({ name: 'twitter:url', content: partUrl });
    }

    if (thumbnail) {
      if (thumbnail.startsWith('/')) {
        thumbnail = 'https://dicom-interactive.com' + thumbnail;
      }
      this.meta.updateTag({ property: 'og:image', content: thumbnail });
    }
  }

  setMetaTags(options: {
    title: string;
    description: string;
    image: string;
    url: string;
    siteName?: string;
    twitterCreator?: string;
    publisher?: string;
    author?: string;
    robots?: string;
    canonical?: string;
    keywords?: string[];
    viewport?: string;
    charset?: string;
    favicon?: string;
  }): void {
    this.title.setTitle(options.title);

    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
    this.meta.removeTag('name="publisher"');
    this.meta.removeTag('name="author"');
    this.meta.removeTag('name="robots"');
    this.meta.removeTag('name="viewport"');
    this.meta.removeTag('charset="UTF-8"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="og:site_name"');
    this.meta.removeTag('name="twitter:card"');
    this.meta.removeTag('name="twitter:title"');
    this.meta.removeTag('name="twitter:description"');
    this.meta.removeTag('name="twitter:image"');
    this.meta.removeTag('name="twitter:creator"');
    this.meta.removeTag('rel="canonical"');

    this.meta.addTags([
      { name: 'description', content: options.description },
      { name: 'keywords', content: options.keywords?.join(', ') || '' },
      { name: 'publisher', content: options.publisher || 'Your Company Name' },
      { name: 'author', content: options.author || 'Your Author Name' },
      { name: 'robots', content: options.robots || 'index, follow' },
      { name: 'viewport', content: options.viewport || 'width=device-width, initial-scale=1' },
      { charset: options.charset || 'UTF-8' },
      { property: 'og:title', content: options.title },
      { property: 'og:description', content: options.description },
      { property: 'og:image', content: options.image },
      { property: 'og:url', content: options.url },
      { property: 'og:site_name', content: options.siteName || 'My Website' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: options.title },
      { name: 'twitter:description', content: options.description },
      { name: 'twitter:image', content: options.image },
      { name: 'twitter:creator', content: options.twitterCreator || '@your_twitter_handle' }
    ]);

    if (options.canonical) {
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', options.canonical);
      document.head.appendChild(link);
    }

    if (options.favicon) {
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('href', options.favicon);
      document.head.appendChild(link);
    }
  }
}
