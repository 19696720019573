import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CollectionService } from '../services/collection.service';
import {
  Category,
  Collection,
  CollectionPage,
  SortCollectionType,
  Tag,
} from '../types/collection';
import { forkJoin, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { CollectionCacheService } from '../services/collection-cache.service';
import { Title, Meta } from '@angular/platform-browser';
import { MetaDataService, ScriptService } from '../services';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css'],
})
export class CollectionComponent
  extends MetaDataService
  implements OnInit, OnDestroy
{
  innerWidth: number = typeof window !== 'undefined' ? window.innerWidth : 0;
  throttle = 200;
  distance = 1.5;
  isLoad = false;
  total = 0;
  limit = 12;
  start = 0;
  postionScroll = 0;
  loading = false;
  filtering = false;
  page: CollectionPage = {
    text: '',
    id: 0,
    documentId: '',
    group_options: [],
    heading: '',
  };
  collections: Collection[] = [];
  categories: Category[] = [];
  tags: Tag[] = [];
  selectedCategories = new Set<string>();
  selectedTags = new Set<string>();
  selectedSort: SortCollectionType = 'createdAt:desc';
  searchQuery = '';
  defaultParams = {
    'fields[0]': 'title',
    'fields[1]': 'basic_price',
    'fields[2]': 'slug',
    populate: 'thumbnail',
    'sort[0]': 'createdAt:desc',
    'populate[0]': 'layouts',
    'pagination[start]': this.start.toString(),
    'pagination[limit]': this.limit.toString(),
  };
  collectionParams = new HttpParams({
    fromObject: this.defaultParams,
  });
  private destroy$ = new Subject<void>();
  private filterSubject = new Subject<void>();
  @ViewChild('filterCol') filterCol!: ElementRef;
  @ViewChild('rowCardList') rowCardList!: ElementRef;
  constructor(
    private collectionService: CollectionService,
    private router: Router,
    private collectionCacheService: CollectionCacheService,
    private activatedRoute: ActivatedRoute,
    private scriptService: ScriptService,
    title: Title,
    meta: Meta
  ) {
    super(title, meta);
  }

  ngOnInit(): void {
    this.scriptService.load(this.scriptService.INIT).then(() => {
      this.isLoad = true;
    });
    this.innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    //initial Seo title, desc, meta ...
    this.setMetaTags({
      description:
        'Khám phá bộ sưu tập các template trang web mẫu hiện đại, chuẩn SEO, và dễ dàng tùy chỉnh. Phù hợp cho mọi ngành nghề, giúp bạn thiết kế website nhanh chóng và hiệu quả.',
      image: 'https://dicom-interactive.com/en/assets/images/logo_small.png',
      publisher: 'Dicom Interactive',
      author: 'Dicom Interactive',
      title: 'Collection | Dicom Interactive',
      url: 'https://dicom-interactive.com/vn/collection/',
      siteName: 'Collection | Dicom Interactive',
      twitterCreator: '@dicomInteractive',
      keywords: [
        'template trang web',
        'mẫu website',
        'thiết kế web',
        'template HTML',
        'template CSS',
        'template đẹp',
        'mẫu giao diện',
        'trang web wordpress',
        'bộ sưu tập mẫu website',
      ],
      canonical: 'https://www.dicom-interactive.com/vn/collection/',
    });
    //initial collection params if any
    this.initialCollectionParams();
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const queryParamsisEmpty = Object.keys(queryParams).length === 0

    const isRefetch = queryParams?.refetch === 'true';
    // load cache
    this.loadCollectionPageInCache();
    this.loadFilterDataInCache();
    this.LoadAuxiliariesInCache();
    this.loadCollectionsDataInCache();

    if (this.collections.length === 0 || isRefetch || queryParamsisEmpty) {
      this.fetchInitialData();
    }

    if (!isRefetch) {
      this.loadPositonScrollInCache();
      setTimeout(() => {
        if (this.postionScroll > 0 && this.collections.length > 0) {
          window.scrollTo({ top: this.postionScroll, behavior: 'smooth' });
        }
      }, 300);
    } else {
      setTimeout(() => {
        this.scrollToTopRowList();
      }, 300);
    }

    //remove backdrop modal
    if (typeof window !== 'undefined') {
      this.removeModalIfExist();
    }


    this.filterSubject
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterOperation();
      });
  }

  private removeModalIfExist(): void {
    const modal = document.querySelector('.modal.show');
    if (modal) {
      modal.classList.remove('show');
    }
    const modalBackdrop = document.querySelector('.modal-backdrop.show');
    if (modalBackdrop) {
      document.body.removeChild(modalBackdrop);
    }
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('modal-open');
    }
  }

  private scrollToTopRowList(): void {
    if (this.rowCardList) {
      this.rowCardList.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  private loadPositonScrollInCache(): void {
    this.postionScroll =
      this.collectionCacheService.getCache()['postionScroll'];
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    const curPosition =
      Math.floor(window.scrollY || document.documentElement.scrollTop) ||
      Math.floor(this.collectionCacheService.getCache()['postionScroll']);
    this.postionScroll = curPosition;
    this.collectionCacheService.setCache({
      collections: this.collections,
      postionScroll: curPosition,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const curWidth = (event.target as Window).innerWidth;
    this.innerWidth = curWidth;

    const modalFilter = document.getElementById('modalfilter-full');
    if (curWidth >= 768 && modalFilter?.classList.contains('show')) {
      modalFilter.classList.remove('show');
      document.body.classList.remove('modal-open');
      document.body.attributes.removeNamedItem('style');
      const modalBackdrop = document.querySelector('.modal-backdrop.show');
      console.log('modalBackdrop', modalBackdrop);
      if (modalBackdrop) {
        document.body.removeChild(modalBackdrop);
      }
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event: Event): void {
  //   let lastScrollTop = 0;
  //   const sidebarHeight = this.filterCol.nativeElement.offsetHeight;
  //   const contentHeight = this.rowCardList.nativeElement.offsetHeight;
  //   const scrollHeight = window.scrollY;
  // }

  onScrollLazyLoad(): void {
    if (this.loading || this.collections.length === this.total) return;

    this.loading = true;
    this.start += this.limit;

    this.collectionParams = this.collectionParams.set(
      'pagination[start]',
      this.start.toString()
    );

    this.collectionService
      .getCollections(this.collectionParams)
      .pipe(
        catchError((error) => {
          console.error('Error fetching collections on scroll:', error);
          return of({
            data: [],
            meta: {
              pagination: {
                page: 0,
                pageSize: 0,
                pageCount: 0,
                total: 0,
              },
            },
          });
        })
      )
      .subscribe(
        (result) => {
          this.collections = [...this.collections, ...result.data];
          this.collectionCacheService.setCache({
            ...this.collectionCacheService.getCache(),
            collections: this.collections,
            start: this.start,
            limit: this.limit,
            total: result.meta.pagination.total,
          });
        },
        (error) => {
          this.loading = false;
          console.error('Unexpected error:', error);
        },
        () => {
          this.loading = false;
        }
      );
  }

  trackById(index: number, item: Collection | Tag | Category): number {
    return item.id;
  }

  private initialCollectionParams(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      Object.keys(params).forEach((key) => {
        if (['categories', 'tags'].includes(key) && params[key]) {
          const selection = new Set<string>(
            decodeURIComponent(params[key]).split(',')
          );
          Array.from(selection).forEach((slug, index) => {
            this.setCollectionArrayParams(key, slug, index);
          });
          if (key === 'categories') this.selectedCategories = selection;
          if (key === 'tags') this.selectedTags = selection;
        }

        if (key === 'search' && params[key]) {
          const text = decodeURIComponent(params[key]);
          this.setCollectionSearchParam(text);
          this.searchQuery = text;
        }

        if (key === 'sort' && params[key]) {
          const sort = decodeURIComponent(params[key]) as SortCollectionType;
          this.setCollectionSortParam(sort);
          this.selectedSort = sort as SortCollectionType;
        }
      });
    });
  }

  private loadFilterDataInCache(): void {
    this.categories = this.collectionCacheService.getCache()['categories'];
    this.tags = this.collectionCacheService.getCache()['tags'];
  }

  private loadCollectionsDataInCache(): void {
    this.collections = this.collectionCacheService.getCache()['collections'];
  }

  private LoadAuxiliariesInCache(): void {
    this.start = this.collectionCacheService.getCache()['start'];
    this.limit = this.collectionCacheService.getCache()['limit'] || 12;
    this.total = this.collectionCacheService.getCache()['total'];
  }

  private loadCollectionPageInCache(): void {
    this.page = this.collectionCacheService.getCache()['page'];
  }

  private fetchInitialData(): void {
    this.loading = true;

    forkJoin({
      page: this.collectionService.getCollectionPage(),
      collections: this.collectionService.getCollections(this.collectionParams),
      categories: this.collectionService.getCategories(),
      tags: this.collectionService.getTags(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ collections, categories, tags, page }) => {
          this.page = page.data;
          this.collections = collections.data;
          this.categories = categories.data;
          this.tags = tags.data;
          this.total = collections.meta.pagination.total;
          this.collectionCacheService.setCache({
            page: page.data,
            categories: categories.data,
            collections: collections.data,
            tags: tags.data,
            total: collections.meta.pagination.total,
            limit: this.limit,
            start: this.start,
          });

          this.loading = false;
        },
        (error) => {
          console.error('Error fetching data:', error);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onCategoryChange(event: Event): void {
    const category = (event.target as HTMLInputElement).value;
    this.updateListSelection(event, this.selectedCategories, category);
  }

  onTagChange(event: Event): void {
    const tag = (event.target as HTMLInputElement).value;
    this.updateListSelection(event, this.selectedTags, tag);
  }

  onSearchChange(event: Event): void {
    this.searchQuery = (event.target as HTMLInputElement).value;

    this.filterSubject.next();
  }

  onClearSearch() {
    this.searchQuery = '';
    this.filterSubject.next();
  }

  onSortChange(event: Event): void {
    this.selectedSort = (event.target as HTMLInputElement)
      .value as SortCollectionType;
    this.filterSubject.next();
  }

  private updateListSelection(
    event: Event,
    selectionSet: Set<string>,
    item: string
  ): void {
    const isChecked = (event.target as HTMLInputElement)?.checked ?? false;

    if (isChecked) {
      selectionSet.add(item);
    } else {
      selectionSet.delete(item);
    }

    this.filterSubject.next();
  }

  private filterOperation() {
    this.updateURLQueryParams();
    this.updateCollectionParams();
    this.getCollectionsByFilter();
  }

  private updateCollectionParams(): void {
    this.resetPaginationOffset();
    this.collectionParams = this.collectionParams
      .keys()
      .reduce((params, key) => {
        return Object.keys(this.defaultParams).includes(key)
          ? params
          : params.delete(key);
      }, this.collectionParams);

    ['search', 'categories', 'tags', 'sort'].forEach((key) => {
      if (key === 'search') {
        this.setCollectionSearchParam(this.searchQuery);
      }

      if (key === 'sort') {
        this.setCollectionSortParam(this.selectedSort);
      }

      if (['categories', 'tags'].includes(key)) {
        const selectionSet =
          key === 'categories' ? this.selectedCategories : this.selectedTags;

        Array.from(selectionSet).forEach((slug, index) => {
          this.setCollectionArrayParams(key, slug, index);
        });
      }
    });
  }

  private resetPaginationOffset(): void {
    this.start = 0;
    this.limit = 12;
    this.collectionParams = this.collectionParams
      .set('pagination[start]', this.start.toString())
      .set('pagination[limit]', this.limit.toString());
  }

  private setCollectionSortParam(sort: SortCollectionType) {
    this.collectionParams = this.collectionParams.set('sort[0]', sort);
  }

  private setCollectionSearchParam(search: string) {
    const param = 'filters[title][$containsi]';
    if (!search) {
      this.collectionParams = this.collectionParams.delete(param);
    } else {
      this.collectionParams = this.collectionParams.set(param, search.trim());
    }
  }

  private setCollectionArrayParams(key: string, value: string, index: number) {
    this.collectionParams = this.collectionParams.set(
      `filters[${key}][slug][$in][${index}]`,
      value
    );
  }

  clearURLQueryParams(): void {
    this.selectedCategories = new Set<string>();
    this.selectedTags = new Set<string>();
    this.searchQuery = '';
    this.selectedSort = 'createdAt:desc';
    this.filterSubject.next();
  }

  private updateURLQueryParams(): void {
    const queryParams = {
      categories: this.buildQuery(this.selectedCategories),
      tags: this.buildQuery(this.selectedTags),
      sort: this.buildQuery(this.selectedSort),
      search: this.buildQuery(this.searchQuery),
      refetch: null,
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private buildQuery(params: Set<string> | string): string | null {
    if (params instanceof Set && params.size > 0) {
      return encodeURIComponent(Array.from(params).join(','));
    } else if (typeof params === 'string' && params !== '') {
      return encodeURIComponent(params);
    } else {
      return null;
    }
  }

  isFiltered(): Boolean {
    return Boolean(
      this.selectedCategories.size > 0 ||
        this.selectedTags.size > 0 ||
        this.searchQuery.trim() !== '' ||
        this.selectedSort !== 'createdAt:desc'
    );
  }

  getCollectionsByFilter(): void {
    this.filtering = true;
    this.collectionService.getCollections(this.collectionParams).subscribe(
      (result) => {
        this.collections = result.data;
        this.total = result.meta.pagination.total;

        this.collectionCacheService.setCache({
          ...this.collectionCacheService.getCache(),
          collections: this.collections,
          start: this.start,
          limit: this.limit,
          total: this.total,
        });

        this.scrollToTopRowList();
        this.filtering = false;
      },
      (error) => {
        console.error('Error fetching collections:', error);
        this.filtering = false;
      },
      () => {
        this.filtering = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.filterSubject.complete();
  }
}
