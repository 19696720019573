<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1 i18n>Kerry Multi Model Transport VENDOR Site</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->

<!--Start Case Study About-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-block upset">
          <img src="assets/images//portfolio/image-13.jpg" alt="case study" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-6 block-1 v-center">
        <div class="common-heading inner-heading text-l pl25">
          <span>About The Project</span>
          <h2>KMMT Vendor Site - Responsive Web Portal</h2>
          <p>A digital transport platform designed to streamline the process of connecting customers with vendors. This
            platform uses advanced algorithms to create seamless matches between customers and vendors, ensuring that
            each transaction is as efficient and effective as possible.</p>
          <p>With features such as real-time tracking, secure payment processing, and a user-friendly interface, this
            platform is poised to revolutionize the way that people think about transportation logistics. Whether you
            are a customer looking to get from point A to point B, or a vendor seeking to expand your reach and grow
            your business, this platform has everything you need to succeed in the digital age.</p>
          <p><strong> ReactJS | NodeJS | Redis | Stripe | AWS </strong></p>
        </div>
        <!--div class="project-platform mt60 pl25">
          <div class="project-platform-used -shadow"><a href="" target="blank"><img src="assets/images//icons/www.png"
            alt="apple"></a></div>
            <div><strong> ⤺ </strong><span style="font-size: smaller;">Click the icon to download the app</span></div>
          </div>
        </div-->
      </div>
    </div>
  </div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="common-heading">
          <span>App Features</span>
          <h2 class="mb30">As per client's need, we have developed a dynamic and mobile-friendly portal.
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h4 class="mt20 mb10">List all current jobs of a selected date range</h4>
        <ul class="list-style-">
          <li>The web project for KMMT is designed to provide a comprehensive logistics management system, featuring
            several key functionalities. Firstly, the website enables vendors to view all current trips within a
            selected date range. This feature allows vendors to efficiently manage their resources and accept or reject
            trips based on their availability and capacity.
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10">View detail of a certain job and update its status as a manager</h4>
        <ul class="list-style-">
          <li>Secondly, the system allows managers to view the details of specific trips and update their status based
            on their progress. This feature enables managers to efficiently monitor the progress of trips and take
            appropriate actions based on their status.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10">Add new truck / driver into the portal</h4>
        <ul class="list-style-">
          <li>Thirdly, administrators can add new trucks and drivers to the system and assign them to future trips. This
            feature ensures that the fleet of trucks and drivers is efficiently managed and assigned to trips based on
            their availability.</li>
        </ul>
      </div>
      <div class="col-lg-6">
        <h4 class="mt20 mb10">Update route information</h4>
        <ul class="list-style-">
          <li>Lastly, The website features pie and bar charts that show the total trips for a selected period of time.
            These charts help vendors adjust their resources by identifying trends, ensuring that KMMT's logistics
            operations remain efficient and cost-effective.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Start Project Challenges-->
<section class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <h2 class="mt20 mb20">Challenges</h2>
        <p>During the development of the KMMT vendor, the team faced several challenges. Firstly, the client server-side
          had IP restrictions, which meant that access from outside of Thailand was not allowed. This restriction caused
          delays in the development process, as the team had to work around the restriction to access the necessary
          data.<br><br></p>
        <p>Secondly, the team encountered a lack of up-to-date API documentation from the client, which made it
          difficult to implement the necessary functionalities. Additionally, the API kept changing, which meant that
          the team had to constantly adapt their code to ensure that it remained functional.<br><br></p>
        <p>Lastly, the client kept changing their infrastructure policies, which caused disruptions to the development
          process. The team had to continually adjust their approach to accommodate the changing policies, which led to
          delays and additional work.<br><br></p>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="project-view m-mt30">
          <img src="assets/images//case-study/goen-view.png" alt="case study" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Project Challenges-->

<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 block-1 v-center">
        <div class="common-heading inner-heading text-l pr25">
          <span>Solutions</span>
          <h2>How We Work</h2>
          <h4>Designing, front-end developing and connecting to KMMT APIs a portal that vendor could use to manage their
            trips and resources.<br><br></h4>
          <p>To overcome IP restriction challenge, our team set up a virtual private network (VPN) to connect to the
            client's server from outside of Thailand. Another option was to have the client whitelist the IP addresses
            of the team members working on the project.
          </p>
          <p>Our team mitigated the API document challenge by performing reverse engineering of the existing API to
            understand its functionality. We also created their own documentation based on the reverse engineering
            process to ensure that they have the necessary information.</p>
          <p>Out team implemented a version control system for the API to track changes and ensure that their code
            remains compatible with the latest version.</p>
          <p>Also, our team worked closely with the client to ensure that they were kept informed of any changes to the
            infrastructure policies. They also built their system to be flexible and adaptable, making it easier to
            adjust to changes in the client's policies.</p>
          <p>By implementing these solutions, our team was able to successfully develop the KMMT vendor, which provides
            a comprehensive logistics management system for the client. The team's adaptability and perseverance were
            crucial in overcoming the obstacles faced during the development process.</p>
        </div>
        <div class="card-nx row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
              <h4>UI/UX Graphic Design</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
              <h4>Front-end Development</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/api.png" alt="case study" class="img-fluid">
              <h4>KMMT API Integration</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
            <div class="icon-card- hfull">
              <img src="assets/images/icons/process.svg" alt="case study" class="img-fluid">
              <h4>Chart Integration</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--End Solutions-->
<div class="case-study pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="porto-slide owl-carousel">
          <div class="screen-splits"> <img src="assets/images/case-study/kmmt-vendor-web-1.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/kmmt-vendor-web-2.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/kmmt-vendor-web-3.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
          <div class="screen-splits"> <img src="assets/images/case-study/kmmt-vendor-web-4.png" alt="case study"
              class="img-fluid" style="border-style: solid; border-width: thin;" /></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Start Navigate-->
<section class="testinomial-section pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
      </div>
      <div class="col-6 v-center">
        <a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
      </div>
    </div>
  </div>
</section>
<!--End Navigate-->

<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
