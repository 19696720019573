<!-- // hyndai banner -->
<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-5">
	<div class="text-block">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 v-center">
					<div class="bread-inner">
						<div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']" i18n>Home</a></li>
                <li><a [routerLink]="['/portfolio' , '']" i18n>Portfolio</a></li>
                <li><span i18n>Project Details</span></li>
              </ul>
						</div>
						<div class="bread-title wow fadeInUp" data-wow-delay=".5s">
							<h1 i18n>Animated Hyundai i20 Banner</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Breadcrumb Area-->
<!--Start Case Study About-->
<section class="case-study pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-lg-6">
				<div class="image-block upset">
					<img src="assets/images/case-study/i20/i20-banner.gif" alt="case study"
						class="img-fluid no-shadow" />
				</div>
			</div>
			<div class="col-lg-6 block-1 v-center">
				<div class="common-heading inner-heading text-l pl25">
					<span i18n>About The Project</span>
					<h2 i18n>Animated Google Ad Manager (GAM) Banner</h2>
					<p i18n>This animated banner is actually a part of Hyundai i20 campaign which is inclusive of a
						set of 6 banners with different dimension.<br></p>
					<p i18n>This banner has 15 second length and a configurable clickTag at the end screen. It
						is created with a tool name Google Web Designer and hosted by Google Ad Manager platform.
					</p>
					<p><strong> HTML5 | Google Ad Manager | Google Web Designer </strong></p>
				</div>
				<!--div class="project-platform mt60 pl25">
						<div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/android.svg"
									alt="android"></a></div>
						<div class="project-platform-used -shadow"><a href="#"><img src="assets/images/icons/apple.svg"
									alt="apple"></a></div>
					</div-->
			</div>
		</div>
	</div>
</section>
<!--End Case Study About-->
<section class="case-study pad-tb bg-gradient6 deep-dark">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-10">
				<div class="common-heading">
					<span i18n>Banner Features</span>
					<h2 class="mb30" i18n>Standard animated banner with Google tool and platform.
					</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6">
				<ul class="list-ul ul-circle">
					<li i18n>Perfectly displayed on any resolution of desktop or mobile browser.
					</li>
					<li i18n>15 second length animation.</li>
				</ul>
			</div>
			<div class="col-lg-6">
				<ul class="list-ul ul-circle">
					<li i18n>Serve with or without clickTag URL.</li>
					<li i18n>Maximum 3 times auto replay.</li>
				</ul>
			</div>
		</div>
	</div>
</section>
<!--Start Solutions-->
<section class="case-study pad-tb bg-gradient5 deep-dark">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 block-1 v-center">
				<div class="common-heading inner-heading text-l pr25">
					<span i18n>Solutions</span>
					<h2 i18n>How We Work</h2>
					<p i18n>As always, we design master banner with assets (images) provided by our client at first. Then
						when we get their approval on it, we apply that same design to various range of dimensions.
					</p>
					<p i18n>During the development stage, we define milestones that we deliver shippable package to our
						client so we could make sure we are going on the right path.</p>
				</div>
				<div class="card-nx row">
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/branding.svg" alt="case study" class="img-fluid">
							<h3>Animate with Google Web Designer</h3>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/back.svg" alt="case study" class="img-fluid">
							<h3>AdForm JS Integration</h3>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/seo.svg" alt="case study" class="img-fluid">
							<h3>Submit to Google Platform</h3>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-6 mt30">
						<div class="icon-card- hfull">
							<img src="assets/images/icons/logo-and-branding.svg" alt="case study" class="img-fluid">
							<h3>UI/UX Graphic Design</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--End Solutions-->

<!--Start Navigate-->
<section class="testinomial-section pad-tb">
	<div class="container">
		<div class="row">
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', previousLink]" class="nav_link" i18n>&laquo; Previous</a>
			</div>
			<div class="col-6 v-center">
				<a [routerLink]="['/', 'portfolio', nextLink]" class="nav_link next_nav_link" i18n>Next &raquo;</a>
			</div>
		</div>
	</div>
</section>
<!--End Navigate-->


<!--Start Testinomial-->
<app-testinomial-component></app-testinomial-component>
<!--End Testinomial-->
