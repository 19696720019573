<!--Breadcrumb Area-->
<section class="breadcrumb-area" data-background="assets/images/banner/6.jpg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 v-center">
        <div class="bread-inner">
          <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
            <ul>
              <li><a [routerLink]="['/']" i18n>Home</a></li>
              <li><span i18n>Web Development Services</span></li>
            </ul>
          </div>
          <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
            <h1 i18n>Web Development Services</h1>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="bread-titlev2">
          <!-- <h1 class="wow fadeInUp" data-wow-delay=".2s" i18n>Web Development Services</h1> -->
          <p class="mt20 wow fadeInUp title-web" data-wow-delay=".4s" i18n>Need a website to start your business online?
            You are in the right place!</p>
          <!-- <a (click)="scroll(pricing)" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s"> -->
          <a [routerLink]="[]" *ngIf="userLanguage=='vn'" (click)="scroll(pricing)" fragment="pricing" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">
            Thiết Kế Web<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
            <a [routerLink]="['/contact']" *ngIf="userLanguage !=='vn'" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Free Quote<i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <div class="statistics-wrap bg-gradient5">
  <div class="container">
    <div class="row small t-ctr mt0">
      <div class="col-lg-3 col-sm-6">
        <div class="statistics">
          <div class="statistics-img">
            <img src="assets/images/icons/deal.svg" alt="happy" class="img-fluid">
          </div>
          <div class="statnumb">
            <span class="counter">40</span><span>+</span>
            <p>Happy Clients</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="statistics">
          <div class="statistics-img">
            <img src="assets/images/icons/computers.svg" alt="project" class="img-fluid">
          </div>
          <div class="statnumb counter-number">
            <span class="counter">60</span><span>+</span>
            <p>Projects Done</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="statistics">
          <div class="statistics-img">
            <img src="assets/images/icons/worker.svg" alt="work" class="img-fluid">
          </div>
          <div class="statnumb">
            <span class="counter">50</span><span>k</span>
            <p>Hours Worked</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="statistics mb0">
          <div class="statistics-img">
            <img src="assets/images/icons/customer-service.svg" alt="support" class="img-fluid">
          </div>
          <div class="statnumb">
            <span class="counter">24</span><span>/</span><span class="counter">7</span>
            <p>Support Available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-services-counter></app-services-counter>
<!--End Hero-->
<!--Start About-->
<section class="service pad-tb">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="image-block upset bg-shape wow fadeIn">
          <img src="assets/images/about/square-image-1.jpg" alt="image" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-8 block-1">
        <div class="common-heading text-l pl25">
          <span i18n>Overview</span>
          <h2 i18n>Creative Web Design Service</h2>
          <p i18n>We at Dicom Interactive build complex web applications to support you to build the most
            sophisticated online products. We make sure to keep up to date with the latest responsive
            and adaptive design trends in order to provide your websites that displayed flawlessly on
            both desktop and mobile across devices, browsers and OS. </p>
          <div class="row">
            <div class="col-md-6 mt30 list-lang">
              <ul class="list-ul ul-check">
                <li>Angular | ReactJS | NextJS | VueJS | NuxtJS.</li>
                <li>Mobile friendly</li>
                <li>Responsive Web Development</li>
                <li>HTML5</li>
                <li>CSS Animations</li>
                <li>PHP | NodeJS | Python | Java | Golang | .NET...</li>
                <li>Redis, MongoDB, PostgreSQL, MySQL, Oracle...</li>
              </ul>
            </div>
            <div class="col-md-6 mt30 list-lang list-lang-mobile">
              <ul class="list-ul ul-check">
                <li>Amazon Cloud | Google Cloud | Azure cloud | DigitalOcean...</li>
                <li>Microservices</li>
                <li>Serverless</li>
                <li>Backend Restful API</li>
                <li>Kubernetes</li>
                <li>Docker</li>
                <li>Auto CI/CD</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start Tech-->
<div class="techonology-used-">
  <div class="container">
    <ul class="h-scroll tech-icons">
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon12.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon10.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon15.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon1.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon2.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon4.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon5.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon3.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon7.png" alt="icon"></a></li>
      <li><a [routerLink]="[]"><img src="assets/images/icons/stack-icon13.png" alt="icon"></a></li>
    </ul>
  </div>
</div>
<!--End Tech-->
<!--Start pricing table -->
<!-- create div because ngShow, ngIf can not build with issue pricing not found -->
<div #pricing>
<section class="block-a1 pad-tb" id="pricing" *ngIf="userLanguage=='vn'">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span>Bảng Giá</span>
          <h2>Bảng Giá Dịch Vụ Thiết Kế Website Chuyên Nghiệp</h2>
          <p class="mb0">Bạn đang tìm kiếm nơi thiết kế website hàng đầu mà không quá tốn kém? Bạn cần xây dựng và khẳng
            định thương hiệu trên thị trường doanh nghiệp. Chung tôi chắc chắn sẽ cung cấp cho bạn một giải pháp hoàn
            chỉnh và chuyên nghiệp.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-4 col-lg-6 col-log-app">
        <div class="pricing-table mt60 web-pricing-col">
          <div class="inner-table web-pricing-child">
            <img src="assets/images/icons/plan-1.svg" alt="Personal" />
            <span class="title">Cơ Bản</span>
            <p class="title-sub">Phù hợp với cá nhân hoặc doanh nghiệp cần website đơn giản và ít tùy biến</p>
            <h2 class="title-sub-web"><sup>từ</sup> 5,9 triệu VND</h2>
            <p class="duration">Thanh toán một lần</p>
            <div class="details" align="left">
              <ul>
                <li>✅ 1 giờ tư vấn để nắm yêu cầu</li>
                <li>✅ Giao diện "tất cả trong một"</li>
                <li>✅ Hiển thị tốt ở mọi độ phân giải</li>
                <li>✅ Quản trị nội dung cơ bản</li>
                <li>✅ Miễn phí 1Gb hosting</li>
                <li>✅ Chứng chỉ SSL miễn phí</li>
                <li>✅ Phí bảo trì: 1.990.000 VND/ năm</li>
                <li>✅ Giá đã gồm VAT</li>
                <li>✅ Thời gian hoàn thành: 2-4 ngày</li>
                <li>&#8203;</li>
                <li>&#8203;</li>
                <li>&#8203;</li>
                <li>&#8203;</li>
              </ul>
            </div>
          </div>
          <div class="web-pricing-child">
            <a href="#" class="btn-main bg-btn lnk" data-toggle="modal" data-target="#pricingPackageModal"
              (click)="selectPackage('gói CƠ BẢN')">Đặt Hàng<i class="fas fa-chevron-right fa-icon"></i> <span
                class="circle"></span></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-log-app">
        <div class="pricing-table best-plan mt60 bg-gradient4 web-pricing-col">
          <div class="inner-table web-pricing-child">
            <img src="assets/images/icons/plan-2.svg" alt="Advance" />
            <span class="title">Tiêu Chuẩn</span>
            <p class="title-sub">Lý tưởng cho các doanh nghiệp vừa và nhỏ cần website chỉnh chu với giá phải chăng</p>
            <h2><sup>từ</sup> 9,9 triệu VND</h2>
            <p class="duration">Thanh toán một lần</p>
            <div class="details" align="left">
              <ul>
                <li>✅ 1 giờ tư vấn để nắm yêu cầu</li>
                <li>✅ Giao diện tới 9 trang chuyên nghiệp</li>
                <li>✅ Hiển thị tốt ở mọi độ phân giải</li>
                <li>✅ SEO cơ bản</li>
                <li>✅ Quản trị nội dung cơ bản</li>
                <li>✅ Miễn phí 2Gb hosting</li>
                <li>✅ Chứng chỉ SSL miễn phí</li>
                <li>✅ Tặng tên miền .com năm đầu tiên</li>
                <li>✅ Sao lưu dữ liệu định kì miễn phí</li>
                <li>✅ Phí bảo trì: 3.990.000 VND/ năm</li>
                <li>✅ Giá đã gồm VAT</li>
                <li>✅ Thời gian hoàn thành: 5-10 ngày</li>
                <li>&#8203;</li>
              </ul>
            </div>
          </div>
          <div class="web-pricing-child">
            <a href="#" class="btn-main bg-btn3 lnk" data-toggle="modal" data-target="#pricingPackageModal"
              (click)="selectPackage('gói TIÊU CHUẨN')">Đặt Hàng<i class="fas fa-chevron-right fa-icon"></i>
              <span class="circle"></span></a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-log-app">
        <div class="pricing-table mt60 web-pricing-col">
          <div class="inner-table web-pricing-child">
            <img src="assets/images/icons/plan-3.svg" alt="Ultimate" />
            <span class="title">Tùy Biến</span>
            <p class="title-sub">Hoàn hảo cho các tổ chức muốn xây dựng website chuyên nghiệp và tiếp cận nhiều khách
              hàng hơn</p>
            <h2>tùy yêu cầu</h2>
            <p class="duration">Thanh toán một lần</p>
            <div class="details" align="left">
              <ul>
                <li>✅ 1+ giờ tư vấn để nắm yêu cầu</li>
                <li>✅ Giao diện 9+ trang chuyên nghiệp</li>
                <li>✅ Hiển thị tốt ở mọi độ phân giải</li>
                <li>✅ SEO chuyên nghiệp</li>
                <li>✅ Quản trị nội dung chuyên nghiệp</li>
                <li>✅ Hỗ trợ viết nội dung và xử lý ảnh</li>
                <li>✅ Miễn phí 4Gb hosting</li>
                <li>✅ Chứng chỉ SSL miễn phí</li>
                <li>✅ Tặng tên miền .com năm đầu tiên</li>
                <li>✅ Sao lưu dữ liệu định kì miễn phí</li>
                <li>✅ Phí bảo trì: 4.990.000 VND/ năm</li>
                <li>✅ Giá đã gồm VAT</li>
                <li>✅ Thời gian hoàn thành: tùy nhu cầu</li>
              </ul>
            </div>
          </div>
          <div class="web-pricing-child">
            <a href="#" class="btn-main bg-btn lnk" data-toggle="modal" data-target="#pricingPackageModal"
              (click)="selectPackage('gói  TUỲ BIẾN')">Đặt Hàng<i class="fas fa-chevron-right fa-icon"></i> <span
                class="circle"></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="showcases-web text-center" *ngIf="currentLocale === 'vn'">
      <a [routerLink]="['/collection']" class="btn-main bg-btn3 lnk text-uppercase">Xem Web Mẫu <i class="bi bi-file-image"></i><span
        class="circle"></span></a>
    </div>
  </div>
</section>
</div>
<!-- End Pricing table -->
<!--Start Service-->
<section class="service-block bg-gradient6 pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="common-heading ptag">
          <span i18n>Service</span>
          <h2 i18n>Our Services</h2>
          <p class="mb30" i18n>We think big and have hands in all leading technology platforms to provide you
            wide array of services.</p>
        </div>
      </div>
    </div>
    <div class="row upset link-hover">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon"><img src="assets/images/icons/service2.svg" alt="service" class="img-fluid" />
          </div>
          <h4 i18n>Single Page Application</h4>
          <p i18n>A single-page application is an app that doesn't need to reload the page during its use and
            works within a browser.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon"><img src="assets/images/icons/service3.svg" alt="service" class="img-fluid" />
          </div>
          <h4 i18n>Custom Web Application</h4>
          <p i18n>Your business has its own workflow. So, we offer tailor-made scalable web applications to fit
            for what you desire.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon"><img src="assets/images/icons/service4.svg" alt="service" class="img-fluid" />
          </div>
          <h4 i18n>SAAS</h4>
          <p i18n>SaaS allows data to be accessed from any device with an internet connection and a web
            browser.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon"><img src="assets/images/icons/logo-and-branding.svg" alt="service"
              class="img-fluid" /></div>
          <h4 i18n>Legacy Application Migration</h4>
          <p i18n>Migrating your age-old applications to cloud in order to reduce its total cost of ownership
            and retain competitiveness.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1s">
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon"><img src="assets/images/icons/service5.svg" alt="service" class="img-fluid" />
          </div>
          <h4 i18n>Full-Stack Web Development</h4>
          <p i18n>Trust our excellent developers with extensive know-how about everything from Backend,
            Database to Frontend.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1.2s">
        <div class="s-block rp-web-service hfull">
          <div class="s-card-icon"><img src="assets/images/icons/service6.svg" alt="service" class="img-fluid" />
          </div>
          <h4 i18n>Support And Maintenance</h4>
          <p i18n>These activities help eliminate possible errors and improve work efficiency and better system
            performance.</p>
        </div>
      </div>
    </div>
    <div class="-cta-btn mt70">
      <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1.3s">
        <p i18n>Hire a <span>Dedicated Developer</span></p>
        <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk" i18n>Hire Now<i
            class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
      </div>
    </div>
  </div>
</section>
<!--End Service-->
<!--Start Key points-->
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 single-image-app2">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Full Stack Web Development</h3>
          <p i18n>We at Dicom Interactive provide full life cycle web applications to support you to build the
            most sophisticated online products. We are expert in creating custom websites for enterprise
            of all sizes. We ensure that our works are kept up to date with the latest tech as well as
            responsive and adaptive design trends, in order to provide you smooth functioning websites
            with flawless displays on both desktop and mobile, across many devices, and on any common
            browsers and OS.</p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
      <div class="col-lg-5 single-image-app1">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development2.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Mobile Web Application</h3>
          <p i18n>There is no more doubt on how mobile devices progressively help businesses connect with
            customers. Concurrently, allowing employees to access data and communicate with coworkers
            securely are also prevalent. If your company is looking into that, Progressive Web Apps
            (PWA) and Hybrids are among the options that are less expensive and save you some time with
            our swift and powerful solutions.</p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 single-image-app2">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Single Page Application</h3>
          <p i18n>Single-Page Application (SPA) is suitable for news apps, dashboards, online polls, event
            apps, ... and other apps that content can be updated independently from the Internet. This
            is a better option when you need something light and speedy, and with great navigation for
            users. SPA is good for being the base of your future native mobile application by using the
            same back-end code.</p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
      <div class="col-lg-5 single-image-app1">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development3.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development4.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Custom Web Application</h3>
          <p i18n>For over a decade, our teams have provided professional web application development services.
            We create modern, scalable and secure solutions that support your digital transformation
            initiatives. We are specialized building industry specific web applications for Travel,
            Education, Transportation, eCommerce, and Healthcare.</p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 single-image-app2">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>SAAS</h3>
          <p i18n>Our teams offer full range service of SaaS development, from R&D to launch and maintenance.
            Being in SAAS business for a sufficient amount of year, we aim to provide secure, scalable
            and reliable systems. We use the most reliable cloud technologies like Amazon AWS, Microsoft
            Azure, Google Cloud... We are experienced in the implementation of many payment methods.
            With our extended knowledge in API development, you can be assured that your SaaS apps can
            be incorporated smoothly with any third-party services. At Dicom Interactive, we keep up
            with all the latest data security regulations like GDPR, PCI-DSS, HIPAA, to make sure your
            solutions aligned with them.</p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
      <div class="col-lg-5 single-image-app1">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development5.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development6.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Legacy Application Migration</h3>
          <p i18n>As industries evolve, businesses often replace their legacy software with new solutions to
            remain the company’s tech capabilities, like being able to add new features or compatible
            with third-party software. This progression keeps your business at the forefront of your
            industry. At Dicom Interactive, we understand that migrating your legacy applications is not
            simply a technology upgrade. Together with your team, we provide technical assessment to
            your current web apps and their business adaptability to your current business needs. We
            offer scalable solutions that support your business growth that built from your legacy
            systems so that your business can continue to benefit from historical data.</p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="service pad-tb light-dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 single-image-app2">
        <div class="text-l service-desc- pr25">
          <h3 class="mb20" i18n>Support And Maintenance</h3>
          <p i18n>Software maintenance includes changes that based on user feedback, advancing business
            requirements, and technical upgrades. We offer constant support and maintenance for your web
            applications. Our dedicated support team takes care of your enterprise web app, makes sure
            it being up with the latest technology, and keeps it bug free. </p>
          <a [routerLink]="['/contact']" (click)="onContactWeb()" class="btn-main bg-btn2 lnk mt30" i18n>Request A Quote <i
              class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
      <div class="col-lg-5 single-image-app1">
        <div class="single-image wow fadeIn">
          <img src="assets/images/service/branding-web-development7.jpg" alt="image" class="img-fluid no-shadow" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Key points-->

<!--Start Process-->
<section class="service-block pad-tb bg-gradient8">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span i18n>Process</span>
          <h2 i18n>Our Web Development Process</h2>
          <p i18n>Our web development process follows a proven approach. We begin with a deep understanding of
            your needs and create a planning template.</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1">
          <img src="assets/images/process/process-1.jpg" alt="Process" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>1</span>
          <h3 i18n>Requirement Gathering</h3>
          <p i18n>What we try to do at this phase is to get clear understanding of your service purposes, your
            goals and your target audience. Whether you have a technical background or are entirely
            unknown to the web and programming world, we could ask the correct and suitable questions
            which could help serve you better afterwards.</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>2</span>
          <h3 i18n>Idea Sketching & Design</h3>
          <p i18n>Once we have the sitemap and the approved wireframes, it's time to lay hands on every page
            of the web application. This is where our stunning graphic designers come in and breathe life
            into those with colors, logo, graphic, animation... So that, then, our web application could
            have a tangible identity.</p>
        </div>
      </div>
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1">
          <img src="assets/images/process/process-2.jpg" alt="Process" class="img-fluid" />
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1">
          <img src="assets/images/process/process-3.jpg" alt="Process" class="img-fluid" />
        </div>
      </div>
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>3</span>
          <h3 i18n>Development & Deployment</h3>
          <p i18n>At this stage where all designs got your approval, our development team start working on two
            major parts: Frontend - the development of client side that is seen by the users - and
            Backend - the soul of the Frontend and transforms the UI into a working web service. After
            going through several meticulous tests on pre-production environment, we deploy your web to
            the live server. That ensures your website is ready for the users and launch.</p>
        </div>
      </div>
    </div>
    <div class="row upset justify-content-center mt60">
      <div class="col-lg-7 v-center order2 order-app2">
        <div class="ps-block">
          <span>4</span>
          <h3 i18n>Support & Maintenance</h3>
          <p i18n>Our process doesn't end after the deployment. In fact, there are quite a few other tasks need
            to be carried out such as providing you necessary documents and working on your feedbacks.
            New request, support and maintenance are equally important. The essence of maintenance as
            this final stage is to continually support and enhance your website.</p>
        </div>
      </div>
      <div class="col-lg-4 v-center order1 order-app1">
        <div class="image-block1 image-web">
          <img src="assets/images/process/process-4.jpg" alt="Process" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Process-->

<!-- why us, some works, let work together -->
<app-services-why-us></app-services-why-us>

<!-- Pricing Package Modal Form-->
<div class="popup-modals">
  <div class="modal" id="pricingPackageModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="common-heading">
            <h4 class="mt0 mb0">Thiết kế Web chuyên nghiệp - {{contact.subject}}</h4>
          </div>
          <button type="button" class="closes" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body pt40 pb60">
          <div class="form-block fdgn2">
            <form #webOrderForm="ngForm" name="carerr-form">
              <div class="fieldsets row">
                <div class="col-md-12 form-group">
                  <!-- subject -->
                  <input style="display: none" type="text" id="subject" name="subject" [ngModel]="contact.subject"
                    #subject="ngModel" placeholder="subject" required data-error="Please fill Out">

                  <input type="text" name="name" id="name" [ngModel]="contact.name" #oname="ngModel" placeholder="Nhập tên của bạn *"
                    required oninput="this.value = this.value.replace(/[\s]+/g, ' ',)" minlength="6">
                    <div *ngIf="submitted || (oname.invalid && (oname.dirty || oname.touched))">
                      <div *ngIf="oname.errors?.required" class="help-block with-errors">Vui lòng nhập tên</div>
                      <div *ngIf="oname.errors?.minlength" class="help-block with-errors">Vui lòng nhập ít nhất 6 ký tự</div>
                    </div>
                </div>
              </div>
              <div class="fieldsets row">
                <div class="col-md-6 form-group phone">
                  <input type="text" id="mobile" name="phone" id="phone" [ngModel]="contact.phone" ng2TelInput
								  [ng2TelInputOptions]="{'preferredCountries': ['vn', 'au']}" required minlength="6" maxlength="12"
								  [minlength]="ophone.errors?.pattern ? null : 6" [maxlength]="ophone.errors?.pattern ? null : 12"
								  pattern="\d+" (countryChange)="onCountryChange($event)" #ophone="ngModel" placeholder="Enter mobile" i18n-placeholder required data-error="Please fill Out">
								  <div *ngIf="submitted || (ophone.invalid && (ophone.dirty || ophone.touched))">
								    <div *ngIf="ophone.errors?.required" class="help-block with-phone">Vui lòng nhập số điện thoại</div>
								    <div *ngIf="ophone.errors?.minlength || phone.errors.maxlength" class="help-block with-phone">Số điện thoại phải có từ 6 đến 12 chữ số</div>
								    <div *ngIf="ophone.errors?.pattern" class="help-block with-phone">Số điện thoại không hợp lệ</div>
								  </div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="email" id="email" name="email" [ngModel]="contact.email" #oemail="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" placeholder="Nhập email *" data-error="Please fill Out" required email>
								  <div *ngIf="submitted || (oemail.invalid && (oemail.dirty || oemail.touched))">
									  <div *ngIf="oemail.errors?.required" class="help-block with-errors">Vui lòng nhập email</div>
									  <div *ngIf="oemail.errors?.pattern" class="help-block with-errors">Email không hợp lệ</div>
								  </div>
                </div>
              </div>
              <div class="fieldsets form-group">
                <textarea type="text" id="message" name="message" [ngModel]="contact.message" #omessage="ngModel"
                  placeholder="Nội dung yêu cầu" required data-error="Please fill Out" rows="4"></textarea>
              </div>
              <div class="form-group from-recaptcha">
                <div class="g-recaptcha" ng-model="g-recaptcha-response" id="recaptcha"></div>
              </div>
              <div class="fieldsets mt20">
                <button type="submit" id="form-submit" class="btn lnk btn-main bg-btn" [disabled]="!submitted" (click)="onSubmitPricingPackage(webOrderForm)">Đặt Hàng<span class="circle"></span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- only for english version -->
<!-- Free Quote Modal Form-->
<div class="popup-modals">
  <div class="modal" id="freeQuoteForm">
    <div class="modal-dialog free-quote-modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="common-heading">
            <h4 class="mt0 mb0">Get Your Free Quote Today!</h4>
          </div>
          <button type="button" class="closes" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body pb60">
          <div class="form-block fdgn2">
            <form #freeQuoteForm="ngForm" name="freeQuoteForm">
            <h6>Tell us about your project, and we'll provide a customized quote.</h6>
              <div class="fieldsets row">
                <div class="col-md-12 form-group">
                  <input type="text" id="name" name="name" [ngModel]="quote.name" #name="ngModel"
                    placeholder="Enter your name *" required data-error="Please fill Out">
                  <div *ngIf="submitted || (name.invalid && (name.dirty || name.touched))">
                    <div *ngIf="name.errors?.required" class="help-block with-errors">Name is required</div>
                  </div>
                </div>
              </div>
              <div class="fieldsets row">
                <div class="col-md-6 form-group">
                  <input type="text" id="mobile" name="phone" ng2TelInput class="phone-input"
                    [ng2TelInputOptions]="{'preferredCountries': ['au', 'us', 'de', 'vn']}"
                    (countryChange)="onCountryChange($event)"
									  pattern="[0-9]{6,12}"
									  [ngModel]="quote.phone" #phone="ngModel" placeholder="Enter your Phone No *" required>

                  <!-- <input type="number" id="phone" name="phone" [ngModel]="quote.phone" #phone="ngModel"
                    placeholder="Enter your Phone No *" required data-error="Please fill Out"> -->
                  <!-- <div *ngIf="submitted || (phone.invalid && (phone.dirty || phone.touched))">
                    <div *ngIf="phone.errors?.required" class="help-block with-errors">Phone is required</div>
                  </div> -->
                  <div *ngIf="submitted || (phone.invalid && (phone.dirty || phone.touched))">
                    <div *ngIf="phone.errors?.required" class="help-block with-phone pb10">Phone is required</div>
                    <div *ngIf="phone.errors?.pattern" class="help-block with-phone pb10">Phone is invalid</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <input type="email" id="email" name="email" [ngModel]="quote.email" #email="ngModel"
                    placeholder="Enter your email *" required data-error="Please fill Out" email>
                  <div *ngIf="submitted || (email.invalid && (email.dirty || email.touched))">
                    <div *ngIf="email.errors?.required" class="help-block with-errors">Email is required</div>
                    <div *ngIf="email.errors?.email" class="help-block with-errors">Email is invalid</div>
                  </div>
                </div>
              </div>
              <div class="fieldsets row">
                <div class="col-md-12 form-group">
                  <input type="text" id="companyName" name="companyName" [ngModel]="quote.companyName" #companyName="ngModel"
                    placeholder="Enter your company" data-error="Please fill Out">
                </div>
              </div>
              <div class="row radio-row">
                <div class="col-md-12 form-group">
                  <div>When is your target date for launching your new website?</div>
                  <br/>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="launching1" name="deadline" [value]="0" [(ngModel)]="quote.deadline" required>
                    <label class="custom-control-label" for="launching1">Within a month</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="launching2" name="deadline" [value]="1" [(ngModel)]="quote.deadline" required>
                    <label class="custom-control-label" for="launching2">1-2 months</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="launching3" name="deadline" [value]="2" [(ngModel)]="quote.deadline" required>
                    <label class="custom-control-label" for="launching3">+2 months</label>
                  </div>
                  <div *ngIf="submitted">
                    <div *ngIf="!quote.deadline && quote.deadline != '0'" class="help-block with-errors">Please select options above</div>
                  </div>
                </div>
              </div>
              <div class="row radio-row">
                <div class="col-md-12 form-group">
                  <div>Are you planning to supply any content, such as text, images, or logo files?</div>
                  <br/>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="contentPlan1" name="contentPlan" [value]="0" [(ngModel)]="quote.contentPlan" required>
                    <label class="custom-control-label" for="contentPlan1">Absolutely, all of them</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="contentPlan2" name="contentPlan" [value]="1" [(ngModel)]="quote.contentPlan" required>
                    <label class="custom-control-label" for="contentPlan2">Just some text and imagery</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="contentPlan3" name="contentPlan" [value]="2" [(ngModel)]="quote.contentPlan" required>
                    <label class="custom-control-label" for="contentPlan3">Not at all</label>
                  </div>
                  <div *ngIf="submitted">
                    <div *ngIf="!quote.contentPlan && quote.contentPlan != '0'" class="help-block with-errors">Please select options above</div>
                  </div>
                </div>
              </div>
              <div class="row radio-row">
                <div class="col-md-12 form-group">
                  <div>How many pages will your website need?</div>
                  <br/>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="pageRequest1" name="pageRequest" [value]="0" [(ngModel)]="quote.pageRequest" required>
                    <label class="custom-control-label" for="pageRequest1">one page</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="pageRequest2" name="pageRequest" [value]="1" [(ngModel)]="quote.pageRequest" required>
                    <label class="custom-control-label" for="pageRequest2">Up to 5</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="pageRequest3" name="pageRequest" [value]="2" [(ngModel)]="quote.pageRequest" required>
                    <label class="custom-control-label" for="pageRequest3">Up to 10</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="pageRequest3" name="pageRequest" [value]="3" [(ngModel)]="quote.pageRequest" required>
                    <label class="custom-control-label" for="pageRequest3">I'm not sure</label>
                  </div>
                  <div *ngIf="submitted">
                    <div *ngIf="!quote.pageRequest && quote.pageRequest != '0'" class="help-block with-errors">Please select options above</div>
                  </div>
                </div>
              </div>
              <div class="row radio-row">
                <div class="col-md-12 form-group">
                  <div>Which particular functionalities or features are you interested in having on the website?</div>
                  <br/>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="features1" name="featureRequest" [value]="0" [(ngModel)]="quote.featureRequest" required>
                    <label class="custom-control-label" for="features1">I want to show my business information, its products and services</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="features2" name="featureRequest" [value]="1" [(ngModel)]="quote.featureRequest" required>
                    <label class="custom-control-label" for="features2">I want to sell product online with shopping cart and checkout process</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="features3" name="featureRequest" [value]="2" [(ngModel)]="quote.featureRequest" required>
                    <label class="custom-control-label" for="features3">I want to have a showcase of work, projects, or personal achievements</label>
                  </div>
                  <br/>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="features4" name="featureRequest" [value]="3" [(ngModel)]="quote.featureRequest" required>
                    <label class="custom-control-label" for="features4">I want to have a blog</label>
                  </div>
                  <br/>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id="features5" name="featureRequest" [value]="4" [(ngModel)]="quote.featureRequest" required>
                    <label class="custom-control-label" for="features5">Other</label>
                  </div>
                  <div *ngIf="submitted">
                    <div *ngIf="!quote.featureRequest && quote.featureRequest != '0'" class="help-block with-errors">Please select options above</div>
                  </div>
                </div>
              </div>
              <div class="fieldsets form-group">
                <textarea type="text" id="message" name="message" [ngModel]="quote.message" #message="ngModel"
                  placeholder="Feel free to describe your business and its core activities *" required data-error="Please fill Out" rows="4"></textarea>
                <div *ngIf="submitted || (message.invalid && (message.dirty || message.touched))">
                  <div *ngIf="message.errors?.required" class="help-block with-errors">Message is required</div>
                </div>
              </div>
              <div class="fieldsets- row">
                <div class="col-md-12 form-group">
                  <div class="custom-file">
                    <input type="file" id="files" name="files" [ngModel]="quote.files" #files="ngModel"
                      accept=".pdf, .doc, .docx" (change)="handleFileInput($event.target.files)" multiple
                      class="custom-file-input mb0">
                    <label class="custom-file-label" for="files">Project briefs, design mockup, or any documents to share</label>
                  </div>
                  <!-- validate file input check -->
                  <!-- <div *ngIf="submitted || (files.invalid && (files.dirty || files.touched))">
                    <div *ngIf="files.errors?.required" class="help-block with-errors" style="margin-top: 16px;">File is
                      required</div>
                  </div> -->
                  <!-- maximum file check -->
                  <!-- <div *ngIf="fileInputError" class="help-block with-errors" style="margin-top: 16px;">
                    {{fileInputError}}</div> -->
                  <!-- file name -->
                  <!-- <div *ngIf="fileChose1 && !fileInputError" style="margin-top: 10px;"><small
                      class="help-block with-messages">1. {{fileChose1}}</small></div>
                  <div *ngIf="fileChose2 && !fileInputError"><small class="help-block with-messages">2.
                      {{fileChose2}}</small></div> -->
                  <p><small>Please upload maximum 2 files Only pdf, docx and doc files.</small></p>
                </div>
              </div>
              <div class="fieldsets mt20">
                <button type="submit" class="lnk btn-main bg-btn" (click)="onSubmit(freeQuoteForm)">Get Free Quote<span class="circle"></span></button>
              </div>
              <div>
                <span class="free-quote-confident">💙 We respect your privacy and will only use the information you provide to prepare your customized quote. Your data is secure and will not be shared with third parties.</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
