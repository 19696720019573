<!--Breadcrumb Area-->
<section class="breadcrumb-area banner-4">
  <div class="text-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 v-center">
          <div class="bread-inner">
            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
              <ul>
                <li><a [routerLink]="['/']">Home</a></li>
                <li><span>Team</span></li>
              </ul>
            </div>
            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
              <h1>Our Leaders</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<!--Start Team Leaders-->
<section class="team bg-gradient99 pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span>We Are Awesome</span>
          <h2>Our Managing Directors</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/DanhLe.jpg" alt="team" class="image" />
              <img src="assets/images/team/DanhLe_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Daniel Le</a></h4>
            <p>Company CEO</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/TanNguyen.jpg" alt="team" class="image" />
              <img src="assets/images/team/TanNguyen_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Tan Nguyen</a></h4>
            <p>Company CTO</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Team Leaders-->
<!--Start Team Members-->
<section class="team pad-tb deep-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="common-heading ptag">
          <span>We Are Awesome</span>
          <h2>Our Core Members</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/ThucNguyen.jpg" alt="team" class="image" />
              <img src="assets/images/team/ThucNguyen_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Suzanne Nguyen</a></h4>
            <p>Business Development Manager (Vietnam)</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/VuQuach.jpg" alt="team" class="image" />
              <img src="assets/images/team/VuQuach_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Steven Quach</a></h4>
            <p>Business Development Manager (Australia)</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/TranLe.jpg" alt="team" class="image" />
              <img src="assets/images/team/TranLe_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Tran Le</a></h4>
            <p>Business Analyst</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div"><a [routerLink]="['/our-team', '']"><img src="assets/images/team/team-3.jpg" alt="team"
                class="img-fluid" /></a></div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Stijn van Velthoven</a></h4>
            <p>Business Development Manager (Europe)</p>
          </div>
        </div>
      </div> -->
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/ChungXa.jpg" alt="team" class="image" />
              <img src="assets/images/team/ChungXa_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Chung Xa</a></h4>
            <p>Software Architect</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/SonDinh.jpg" alt="team" class="image" />
              <img src="assets/images/team/SonDinh_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Son Dinh</a></h4>
            <p>Development Leader</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/NghiNguyen.jpg" alt="team" class="image" />
              <img src="assets/images/team/NghiNguyen_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Nghi Nguyen</a></h4>
            <p>Full-Stack Developer</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/DoPhan.jpg" alt="team" class="image" /><img src="assets/images/team/DoPhan_hover.jpg"
                alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Do Phan</a></h4>
            <p>Full-Stack Developer</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/TrongNguyen.jpg" alt="team" class="image" />
              <img src="assets/images/team/TrongNguyen_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Trong Nguyen</a></h4>
            <p>Full-Stack Developer</p>
          </div>
        </div>
      </div>


      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/AnhTran.jpg" alt="team" class="image" />
              <img src="assets/images/team/AnhTran_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Anh Tran</a></h4>
            <p>Android Developer</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/ChauHoang.jpg" alt="team" class="image" />
              <img src="assets/images/team/ChauHoang_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Chau Hoang</a></h4>
            <p>iOS Developer</p>
          </div>
        </div>
      </div>


      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/VyVo.jpg" alt="team" class="image" />
              <img src="assets/images/team/VyVo_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Vy Vo</a></h4>
            <p>Quality Control Technician</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/HungNguyen.jpg" alt="team" class="image" />
              <img src="assets/images/team/HungNguyen_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Hung Nguyen</a></h4>
            <p>Designer</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/LongTrieu.jpg" alt="team" class="image" />
              <img src="assets/images/team/LongTrieu_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Long Trieu</a></h4>
            <p>Designer</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/NgocNguyen.jpg" alt="team" class="image" />
              <img src="assets/images/team/NgocNguyen_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Ngoc Nguyen</a></h4>
            <p>Accountant</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/VietAnhTran.jpg" alt="team" class="image" />
              <img src="assets/images/team/VietAnhTran_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">Anh (Viet) Tran</a></h4>
            <p>Quality Control Intern</p>
          </div>
        </div>
      </div>
<!--
      <div class="col-lg-3 col-sm-6">
        <div class="full-image-card hover-scale">
          <div class="image-div image-wrapper">
            <a [routerLink]="['/our-team', '']">
              <img src="assets/images/team/VietAnhTran.jpg" alt="team" class="image" />
              <img src="assets/images/team/VietAnhTran_hover.jpg" alt="team" class="image-hover" />
            </a>
          </div>
          <div class="info-text-block">
            <h4><a [routerLink]="['/our-team', '']">New</a></h4>
            <p>New</p>
          </div>
        </div>
      </div> -->


    </div>
  </div>
</section>
<!--End Team Members-->
