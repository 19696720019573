import { Injectable } from '@angular/core';
import { Router, NavigationEnd, UrlSerializer } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {
  private previousUrl: string | null = null;
  private previousQueryParams: { [key: string]: string } | null = null;

  constructor(private router: Router,private urlSerializer: UrlSerializer) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = event.urlAfterRedirects;
      this.previousQueryParams = this.parseQueryParams(event.urlAfterRedirects);
    });
  }

  private parseQueryParams(url: string): { [key: string]: string } | null {
    const tree = this.urlSerializer.parse(url);
    if(tree.queryParams){
      return tree.queryParams
    }
    return null;
  }

  public getPreviousUrl(): string | null {
    return this.previousUrl;
  }

  public getPreviousQueryParams(): { [key: string]: string } | null {
    return this.previousQueryParams;
  }
}
