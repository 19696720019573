export const RICH_BANNERS = [
  {
    title: '360° Product',
    slug: '360-product-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/360InteractiveProduct/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>Showcase a 3D product with 360° rotation, allowing users to view every angle interactively. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the 360° Interactive Product banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/360InteractiveProduct.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Interactive 360° drag-to-rotate functionality.</li>
                <li>Supports .glb file format exported from 3D modeling tools.</li>
                <li>Mobile and desktop responsive.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Test the 3D rendering on various devices to ensure smooth performance.</li>
                <li>Optimize .glb files for faster loading times.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p>Perfect for showcasing products with intricate details, the 360° Interactive Product banner provides a unique and engaging experience for potential buyers.</p>
        </div>`,
    width: 300,
    height: 600,
  },
  {
    title: 'Scratch',
    slug: 'scratch-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/ScratchToReveal/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>A fun and engaging banner where users can swipe to reveal hidden content beneath a top layer. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the Scratch to Reveal banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/ScratchToReveal.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Interactive scratch-to-reveal effect.</li>
                <li>Perfect for promotional campaigns or mystery content.</li>
                <li>Responsive design ensures smooth functionality across devices.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Ensure the top and bottom images align correctly for a clean reveal.</li>
                <li>Test swipe functionality across devices to confirm compatibility.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p>
            The Scratch to Reveal banner is an exciting way to engage users, making it perfect for promotional events or interactive campaigns.
            </p>
        </div>`,
    width: 300,
    height: 600,
  },
  {
    title: 'Before/After',
    slug: 'before-after-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/BeforeAfter/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>This banner showcases a dynamic before-and-after effect. Users can drag their mouse or swipe on mobile devices to compare two images seamlessly. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the Before &amp; After banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/BeforeAfter.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Drag-and-swipe functionality for a smooth before-and-after effect.</li>
                <li>Perfect for visual comparisons, such as product transformations.</li>
                <li>Fully responsive for mobile and desktop use.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Image quality is crucial to maximize the impact of the effect.</li>
                <li>Test swipe functionality on mobile to ensure seamless interaction.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p> The Before &amp; After banner offers a highly engaging way to demonstrate transformations, ideal for product showcases or campaigns requiring a comparison.</p>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Embed Video',
    slug: 'embed-video-banner',
    show: false,
    class: '',
    src: 'https://dicom-interactive.com/banners/demo/InPageVideo/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>An integrated video banner that supports both hosted MP4 videos and YouTube URLs, offering flexibility in media presentation. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the In-Page Video banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/InPageVideo.html"
 style="border: none;"
 width="300"
 height="600"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Supports autoplay, play/pause controls, and mobile-friendly designs.</li>
                <li>Works seamlessly with both hosted videos and YouTube URLs.</li>
                <li>Lightweight implementation with no additional scripts required.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Ensure video formats comply with hosting requirements (e.g., MP4).</li>
                <li>YouTube URLs must be valid and properly embedded.</li>
            </ul>

            <h4>Why Choose?</h4>
           <p>The In-Page Video banner provides a dynamic way to communicate through multimedia, enhancing user engagement with visual storytelling.</p>
        </div>
  `,
    width: 300,
    height: 600,
  },
  {
    title: 'Gallery',
    slug: 'gallery-banner',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/GalleryWTransition/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>An elegant banner designed to display a gallery of images with smooth transitions and pagination controls. This banner can be hosted on Google Ad platforms or non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the Gallery banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/GalleryWTransition.html"
 style="border: none;"
 width="300"
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Automatic transitions between images.</li>
                <li>Pagination dots for manual navigation.</li>
                <li>Fully customizable transition effects.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Use high-resolution images to ensure the gallery looks professional.</li>
                <li>Test auto-play functionality to confirm a seamless experience.</li>
            </ul>

            <h4>Why Choose?</h4>
           <p>The Gallery with Transition banner is ideal for displaying multiple visuals in a clean and modern layout, creating an appealing user experience.</p>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Parallax',
    slug: 'parallax-banner',
    class: '',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/Parallax/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Parallax Banner Hosting Instructions</h2>
            <p>Our <strong>Parallax banners</strong> are designed to provide a rich, interactive scrolling experience. They dynamically react to user scrolling, offering a visually engaging element to any webpage. These banners can be hosted on non-Google platforms with ease, using a custom JavaScript file (dic.js) to handle the parallax effect and iframe integration.</p>

            <h3>Integration Steps for Non-Google Platforms</h3>
            To integrate the Parallax banner into your website, follow these steps:
            <h4>1. Include the JavaScript File</h4>
            <ul>
                <li>Add the following &lt;script&gt; tag to the &lt;head&gt; of the webpage where the banner will be embedded:
                    <pre>&lt;script src="https://dicom-interactive.com/banners/dic.js"&gt;&lt;/script&gt;</pre>
                </li>
                <li>Alternatively, you can host dic.js alongside the Parallax banner files and update the src path accordingly.</li>
            </ul>

            <h4>2. Embed the Parallax Banner</h4>
            <ul>
                <li>Add a scrollable container (&lt;div&gt;) and an iframe for the banner into your webpage:
                    <pre>    &lt;div id="scrollableElement"&gt;
        &lt;iframe
            id="myParallaxIframe"
            class="dic dic-parallax"
            scroller="#scrollableElement"
            scrolling="no"
            src="https://yourdomain.com/path-to-banner/banner.html"
            style="border: none;"
            width="300"
            height="250"&gt;
        &lt;/iframe&gt;
    &lt;/div&gt;
                    </pre>
                </li>
                <li>Key Attributes:<br>
                    <ul>
                        <li>scroller=“#scrollableElement”: Links the iframe to the specified scrollable container for proper parallax synchronization.</li>
                        <li>scrolling=“no”: Ensures the iframe doesn’t introduce its own scrollbars.</li>
                    </ul>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li><strong>Interactive Scrolling:</strong> The Parallax banner reacts dynamically to user scrolling, creating a smooth, visually engaging experience.</li>
                <li><strong>Customizable Scroller:</strong> You can specify any scrollable element (e.g., #scrollableElement) to control the parallax effect.</li>
                <li><strong>Easy Integration:</strong> Add the dic.js file and a simple iframe structure to your site—no additional configurations required.</li>
            </ul>

            <h4>Notes</h4>
            <ol>
                <li>The width and height of the iframe should match the banner’s dimensions.</li>
                <li>Ensure the src in the &lt;iframe&gt; points to the correct banner HTML file location on your hosting platform.</li>
                <li>The scrollable container (#scrollableElement) should have the appropriate dimensions and styles to allow scrolling:
                    <pre>    #scrollableElement {
        height: 100vh; /* Full viewport height */
        overflow-y: scroll; /* Enable vertical scrolling */
    }
                    </pre></li>
            </ol>

            <h4>Why Choose Parallax Banners?</h4>
            <ul>
                <li><strong>Enhanced Engagement:</strong> Parallax banners are perfect for capturing user attention and boosting interaction.</li>
                <li><strong>Flexible Hosting:</strong> Our banners can be hosted on non-Google platforms while retaining the same functionality as Google-hosted banners.</li>
                <li><strong>Lightweight Integration:</strong> With our dic.js file, implementation is seamless and efficient.</li>
            </ul>
        </div>`,
    width: 300,
    height: 250,
  },
  {
    title: 'Expandable',
    class: 'dic dic-expandable',
    slug: 'expandable-banner',
    show: false,
    src: 'https://dicom-interactive.com/banners/demo/Expandable/banner.html',
    description: `<div class="instructions ck-content">
            <h2>Expandable Banner Hosting Options</h2>
            <p>Our expandable banners are highly versatile and can be hosted on both <strong>Google Ad platforms</strong> (e.g., Google Ads, DV360) and <strong>non-Google hosting platforms</strong>. Below, we detail the requirements for hosting banners on non-Google platforms, including how to set up our expandable banners with a custom JavaScript handler.</p>

            <h3>Hosting on Non-Google Platforms</h3>
            <p>
            To host our expandable banners on non-Google platforms, we provide a lightweight JavaScript file (dic.js) that ensures seamless expand/collapse functionality by dynamically resizing the iframe. This allows the expandable banner to function exactly as intended, while the embedded content remains user-friendly and responsive.
            </p>
            <h3>Integration Steps for Non-Google Platforms</h3>
            <h4>1. Include the JavaScript File</h4>
            <ul>
                <li>Add the following &lt;script&gt; tag to the &lt;head&gt; of the website where the banner will be embedded:
                    <pre>&lt;script src="https://dicom-interactive.com/banners/dic.js"&gt;&lt;/script&gt;</pre></li>
                <li>Alternatively, you can host dic.js alongside the expandable banner files and update the src path accordingly.</li>
            </ul>
            <h4>2. Embed the Expandable Banner</h4>
            <p>Add the iframe block to your webpage where you want the banner to appear. The example below demonstrates embedding an expandable banner:</p>
            <pre>&lt;iframe
    id="myExpandableIframe"
    class="dic dic-expandable"
    src="https://yourdomain.com/path-to-banner/banner.html"
    style="border: none;"
    width="320"
    height="50"&gt;
&lt;/iframe&gt;
            </pre>
            <h4>Key Features</h4>
            <ul>
                <li><strong>Dynamic Resizing:</strong> dic.js handles the iframe resizing automatically. When users interact with the banner, the iframe resizes dynamically to display the expanded state, then collapses when required.</li>
                <li>Flexibility: Our banners can integrate into any hosting platform while maintaining the same expand/collapse functionality as Google-hosted banners.</li>
                <li>Easy Setup: Simply include the JavaScript file and iframe code in your website—no complex configurations needed.</li>
            </ul>
            <h4>Notes</h4>
            <ul>
                <li>Ensure the iframe src points to the correct banner HTML file location on your hosting platform.</li>
                <li>The initial width and height values in the &lt;iframe&gt; tag should match the dimensions of the collapsed state of your banner (e.g., 320x50).</li>
                <li>The dic.js file should either be hosted at the default URL <a href="https://dicom-interactive.com/banners/dic.js" target="_blank">https://dicom-interactive.com/banners/dic.js</a> or alongside your banner files.</li>
            </ul>
        </div>`,
    width: 320,
    height: 50,
  },
];

export const SERVICES_BANNER = [
  {
    title: 'Standard Banner',
    description:
      'Our standard banners are crafted to grab attention and drive engagement while meeting industry standards for optimized loading and seamless performance across platforms.',
    img: 'assets/images/icons/Standard.png',
  },
  {
    title: 'Rich Media Banner',
    description:
      'With advanced features like video integration, animations, and interactive elements, our rich media banners elevate user engagement and make your campaigns more impactful.',
    img: 'assets/images/icons/Rich.png',
  },
  {
    title: 'Design Layout Creation',
    description:
      'From concept to execution, we create bespoke banner designs tailored to your marketing goals, helping your business stand out in a competitive landscape.',
    img: 'assets/images/icons/Design.png',
  },
  {
    title: 'Design Layout Adaption',
    description:
      'We expertly adapt your existing designs to fit specific banner dimensions and formats while preserving brand identity and ensuring visual consistency.',
    img: 'assets/images/icons/Adapt.png',
  },
];

export const TECH_STACK_BANNER = [
  { src: 'assets/images/icons/Photoshop.png', title: 'Photoshop' },
  { src: 'assets/images/icons/Illustrator.png', title: 'Illustrator' },
  { src: 'assets/images/icons/GWD.png', title: 'GWD' },
  { src: 'assets/images/icons/Blender.png', title: 'Blender' },
  { src: 'assets/images/icons/Animate.png', title: 'Animate' },
];

const standardDescription = `
  <div class="instructions ck-content">
            <h2>Banner Hosting Instructions</h2>
            <p>This banner is a visually captivating HTML canvas banner designed with animation to convey your brand message effectively. Created using tools like Google Web Designer or Adobe Animate, it can be seamlessly hosted on Google Ad platforms or other non-Google platforms.</p>
            <h3>For non-Google platforms:</h3>
            <h4>Add the Banner</h4>
            <ul>
                <li>
                    Embed the standard banner using the following &lt;iframe&gt; code:
                    <pre>&lt;iframe
 src="https://yourdomain.com/path-to-banner/StandardBanner.html" 
 style="border: none;" 
 width="300" 
 height="250"&gt;
&lt;/iframe&gt;
                    </pre>
                </li>
            </ul>

            <h4>Key Features</h4>
            <ul>
                <li>Eye-catching animations designed to maximize user engagement.</li>
                <li>Lightweight and optimized for fast loading across platforms.</li>
                <li>Fully responsive and adaptable for both desktop and mobile environments.</li>
            </ul>

            <h4>Notes</h4>
            <ul>
                <li>Ensure the hosting platform supports HTML5 banners for optimal performance.</li>
                <li>Use high-quality assets and images to maintain visual clarity on various devices.</li>
            </ul>

            <h4>Why Choose?</h4>
            <p>The standard banner is an essential tool for digital campaigns, offering compatibility with all major ad platforms and providing a visually appealing and professional representation of your brand. It is ideal for businesses aiming to deliver impactful messaging through high-quality animations.</p>
        </div>
`

export const STANDARD_BANNERS = [
  { 
    src: 'assets/images/media-banners/liquors.png',
    title: 'Liquors Banner', 
    slug: 'liquors-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Liquor-Loung/index.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/parched_panther.png',
    title: 'Parched Panther',
    slug: 'parched-panther-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Parched-Panther/index.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
  { 
    src: 'assets/images/media-banners/toast_co.png',
    title: 'Toast & Co',
    slug: 'toast-co-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Toast&Co/banner.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
  {
    src: 'assets/images/media-banners/vine_brations.png',
    title: 'Vine Brations',
    slug: 'vine-brations-banner',
    srcBanner: 'https://dicom-interactive.com/banners/demo/Publish-Vinebrations/index.html',
    description: standardDescription,
    width: 300,
    height: 600,
  },
];
