const projects = [
    'white-spa-and-nail',
    'keazaccess-platform',
    'ucademy-platform',
    'mmhf-site',
    'whipev-mobile-app',
    'tdholding-site',
    'movinavir-site',
    'ask2go-mobile-applications',
    'goen-e-learning-system',
    'envoca-mobile-app',
    'meoto-auto-trader',
    '3d-hyundai-i30-banner',
    'ascentis-homepage-design',
    'hyundai-i20-banner',
    'cules-news-mobile-app',
    'fresy',
    // 'wildimports'
];

export function previousEvent(currentLink) {
    let index = projects.indexOf(currentLink);
    if (index == -1) {
        console.log('link not found');
        return;
    }
    const lastIndex = projects.length - 1;
    if (index == 0) {
        return projects[lastIndex];
    }
    return projects[--index];
}

export function nextEvent(currentLink) {
    let index = projects.indexOf(currentLink);
    if (index == -1) {
        console.log('link not found');
        return;
    }
    const lastIndex = projects.length - 1;
    if (index == lastIndex) {
        return projects[0];
    }
    return projects[++index];
}
