<!--Breadcrumb Area-->
<section class="breadcrumb-area" data-background="assets/images/banner/11.jpg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 v-center">
        <div class="bread-inner">
          <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
            <ul>
              <li><a [routerLink]="['/']" i18n>Home</a></li>
              <li><span i18n>Offshore Development Center</span></li>
            </ul>
          </div>
          <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
            <h1 i18n>Offshore Development Center</h1>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="bread-titlev2">
          <p class="mt20 wow fadeInUp" data-wow-delay=".4s" i18n>We help businesses scale up delivery capacity with our high
            standard offshore development services</p>
          <a [routerLink]="['/contact']" class="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s" i18n>Get Quote
            <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Breadcrumb Area-->
<section class="about-agency pad-tb block-1">
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-6 v-center">
					<div class="about-image">
						<img src="assets/images/about/company-about.png" alt="About Us" class="img-fluid" />
					</div>
				</div> -->
      <div class="col-lg-12">
        <div class="common-heading text-l offshore">
          <span i18n>Services</span>
          <h2 i18n>Offshore Development Center</h2>
          <p i18n>We help businesses scale up delivery capacity with our high standard offshore development services. Our
            solutions resolve the challenges that you might be having such as project deadlines, limited team headcount,
            Project budget, or out of expertise scope, ... and help you meet your business milestones of growth.
          </p>
          <h4 i18n>What benefits our partnership brings</h4>
          <div class="child-content">
            <ul>
              <h6 i18n>Cost Optimization</h6>
            </ul>
            <p i18n>We offer high quality services at a reasonable price range. That gives our clients a significant cost
              reduction without any compromising on quality.</p>
            <ul>
              <h6 i18n>Talented IT professionals</h6>
            </ul>
            <p i18n>Our teams are hands-on with the latest technologies and their best implementation.
              All developers at <a [routerLink]="['/']" class="menu-links">Dicom Interactive</a> have over 4 years of IT
              experience, and possess horizontal expertise across various domains, with focus on education,
              transportation, healthcare, entertainment, game, F&amp;B, automobile, and travel
              industry.</p>
            <ul>
              <h6 i18n>Flexible plans</h6>
            </ul>
            <p i18n>Whether you opt for a dedicated team, a team extension plan, or a project-based approach, we make
              sure you can always scale your development capability with ease.</p>
            <ul>
              <h6 i18n>DevOps as an added value</h6>
            </ul>
            <p i18n><a [routerLink]="['/']" class="menu-links">Dicom Interactive</a> offers DevOps on demand, as an added
              value to our offshore development services. Our DevOps engineers can perform an end2end analysis of your
              IT process and software infrastructure to help you
              optimize your development, deployment, security, delivery, and so on so that your software can meet the
              required business objectives and have risk mitigation.</p>
            <ul>
              <h6 i18n>International standard process</h6>
            </ul>
            <p i18n>Using resources from our offshore development center gives you access to our worldwide industry set-ups.
              Our agile project management allows you to have regular updates on the development progress and results,
              and make necessary adjustments.</p>
            <ul>
              <h6 i18n>Technical collaboration support</h6>
            </ul>
            <p i18n>We offer extra facilitation to clients and nearshore development centers with design, documentation, and
              technical specs, user manuals...</p>
            <ul>
              <h6 i18n>Effective communications</h6>
            </ul>
            <p i18n>Communication is the key to every successful partnership. Our team members, from junior to senior levels,
              are articulate, capable of understanding and handling complex contexts of commands, and well versed in
              both Vietnamese and English.</p>
            <ul>
              <h6 i18n>Cultural compatibility</h6>
            </ul>
            <p i18n>Being part of the dynamic workforce of Vietnam, especially in the IT sector, our developers inherit high
              cultural adaptability.</p>
            <ul>
              <h6 i18n>Business catalyst</h6>
            </ul>
            <p i18n>In the attempt of the Vietnam government to turn Vietnam into the safe and ideal destination for BPO, we
              benefit from the favorable economical policies for software development sector, which our clients also get
              benefits of in our long&ndash;term partnership.</p>
          </div>
          <h4 i18n>Our models</h4>
          <div class="child-content">
            <h6 i18n>Dedicated team</h6>
            <p i18n>We help you design and form a qualified development team on a long-term basis. Companies that can take
              charge of project management prefer this high-level outsourcing approach.</p>
            <h6 i18n>Team extension</h6>
            <p i18n>When you need the right experts for your specific tasks in specific projects, this approach is for you.
              We ensure you have highly skilled software developers with the right expertise and cultural fit.</p>
            <h6 i18n>Project based</h6>
            <p i18n>This is complete technical outsourcing that allows you to fully focus on the business aspects of your
              product while letting us take care of the whole cycle of software product development, like project
              management, technical decisions, development, testing,... This approach is the choice of many start-ups
              and SME businesses.</p>
          </div>
          <h4 i18n>Our experts</h4>
          <ul class="disc list-item offshore-lang">
            <li i18n>Back-end developers</li>
            <li i18n>Front-end developers</li>
            <li i18n>Full-stack developers</li>
            <li i18n>Mobile developers</li>
            <li i18n>HTML coders</li>
            <li i18n>Web developers</li>
            <li i18n>QA automation engineers</li>
            <li i18n>QA manual engineers</li>
            <li i18n>System architects</li>
            <li i18n>System admins</li>
            <li i18n>Data warehouse engineers</li>
            <li i18n>DevOps engineers</li>
            <li i18n>Project managers</li>
            <li i18n>Business Analysts</li>
            <li i18n>Customers experience analysts</li>
            <li i18n>Security specialists</li>
            <li i18n>UI/UX designers</li>
            <li i18n>Graphic designers</li>
            <li i18n>FA artists</li>
          </ul>
          <h4 i18n>Our Tech Stack</h4>
          <ul class="square list-item offshore-lang">
            <li i18n>Angular / ReactJS / NextJS / VueJS / NuxtJS</li>
            <li i18n>HTML5</li>
            <li i18n>CSS Animations</li>
            <li i18n>PHP | NodeJS | Python | Java | Golang | .NET...</li>
            <li i18n>Redis, MongoDB, PostgreSQl, MySql, Oracle...</li>
            <li i18n>Amazon Cloud | Google Cloud | Azure cloud | DigitalOcean...</li>
            <li i18n>Microservices</li>
            <li i18n>Serverless</li>
            <li i18n>Backend Restfull API</li>
            <li i18n>Kubernets</li>
            <li i18n>Docker</li>
            <li i18n>Auto CI/CD</li>
            <li i18n>IPhone / IPad Development</li>
            <li i18n>Apple Watch</li>
            <li i18n>Swift</li>
            <li i18n>Objective-C</li>
            <li i18n>Java</li>
            <li i18n>Kotlin</li>
            <li i18n>Android TV</li>
            <li i18n>Custom-embedded Android ROM</li>
            <li i18n>React Native</li>
            <li i18n>Flutter</li>
            <li i18n>Firebase</li>
            <li i18n>Cloud Computing</li>
            <li i18n>Serverless</li>
            <li i18n>Backend Restfull API</li>
          </ul>
          <h4 i18n>How to set up an ODC with Us</h4>
          <ul class="list-item2">
            <li i18n>Requirement Gathering</li>
            <li i18n>Team structure Designing</li>
            <li i18n>Acceptance criteria Deciding</li>
            <li i18n>Communication method Setting</li>
            <li i18n>Software development Managing</li>
            <li i18n>Delivery process Setting</li>
          </ul>
        </div>
        <div class="row in-stats small about-statistics">
          <div class="col-lg-4 col-sm-4">
            <div class="statistics">
              <div class="statnumb counter-number">
                <span class="counter">40</span><span>+</span>
                <p i18n>Happy Clients</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="statistics">
              <div class="statnumb">
                <span class="counter">50</span><span>k</span>
                <p i18n>Hours Worked</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="statistics mb0">
              <div class="statnumb counter-number">
                <span class="counter">60</span><span>+</span>
                <p i18n>Projects Done</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End About-->
<!--Start why-choose-->
<section class="why-choose pad-tb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="common-heading">
          <span i18n>We Are Awesome</span>
          <h2 class="mb30" i18n>Why Choose Us</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="0.2s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/ontime.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>On Time Delivery</h4>
            <p i18n>We value your precious time, so we try our best to deliver the good on time and within
              budget.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".4s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/quality.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Top Quality</h4>
            <p i18n>Our testing experts efficiently collaborate with you to achieve the best product quality.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".6s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/accountability.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Accountability</h4>
            <p i18n>We fully grasp the importance of listening and understanding your business and
              objectives.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30  wow fadeIn" data-wow-delay="0.8s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/price.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Reasonable Price</h4>
            <p i18n>We optimize our operation cost so that we could provide you services with bargain price.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.0s">
        <div class="s-block wide-sblock hfull">
          <div class="s-card-icon"><img src="assets/images/icons/process.svg" alt="service" class="img-fluid">
          </div>
          <div class="s-block-content">
            <h4 i18n>Professional Process</h4>
            <p i18n>We standardize Agile process to improve both team productivity and project quality.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End why-choose-->
